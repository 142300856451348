import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import axios from "axios";
import { ADMIN_TOKEN, BASE_URL } from "../../utils/Constants";
import LogoutAction from "./LogoutAction";
import PvType, { GetPVHistory } from "../type/pvType";

interface Action {
  type: string;
  payload?: any;
  token?: any;
}

const PVAction = {
  getPvHistory:
    (data: GetPVHistory): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);

        const result: any = await axios.post(
          BASE_URL + "employer/pv-history",
          {
            search: data.search,
            limit: data.limit,
            page: data.page,
            date: data.date,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "application/json",
            },
          },
        );

        dispatch({
          type: PvType.SET_PV_HISTORY_LIST,
          payload: {
            data: result.data.data.data,
            currentPage: result.data.data.current_page,
            lastPage: result.data.data.last_page,
            totalData: result.data.data.total,
            from: result.data.data.from,
            to: result.data.data.to,
          },
        });
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },
};

export default PVAction;
