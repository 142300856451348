const pvType = {
  SET_PV_HISTORY_LIST: "SET_PV_HISTORY_LIST",
};

export interface GetPVHistory {
  search: string;
  limit: number;
  page: number;
  date: string;
}

export default pvType;
