import WebLayout from "../../layout/WebLayout";
import Breadcrumb from "../../components/Breadcrumb";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardAction from "../../stores/action/dashboardAction";
import toast from "react-hot-toast";
import { RootState } from "../../stores";
import { Link } from "react-router-dom";

function Home() {
  const dispatch = useDispatch<any>();

  const { contact, emc, erm, stateSalary } = useSelector(
    (state: RootState) => state.dashboard,
  );

  const [loadingContacts, setLoadingContacts] = useState<boolean>(false);
  const [loadingEMC, setLoadingEMC] = useState<boolean>(false);
  const [loadingERM, setLoadingERM] = useState<boolean>(false);
  const [loadingStateSalary, setLoadingStateSalary] = useState<boolean>(false);

  const getData = useCallback(async () => {
    try {
      // setLoadingContacts(true);
      await dispatch(DashboardAction.getContactCount());
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      // setLoadingContacts(false);
    }

    try {
      // setLoadingEMC(true);
      await dispatch(DashboardAction.getEMCCount());
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      // setLoadingEMC(false);
    }

    try {
      // setLoadingERM(true);
      await dispatch(DashboardAction.getERMCount());
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      // setLoadingERM(false);
    }

    try {
      // setLoadingStateSalary(true);
      await dispatch(DashboardAction.getStateSalaryCount());
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      // setLoadingStateSalary(false);
    }
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <WebLayout pageName={"Home"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <Breadcrumb mainTitle={"Dashboard"} subTitle={"Dashboard"} />
            </div>
          </div>

          {/* Contacts */}
          <div className="row">
            <h5>STATS</h5>
            <div className="row">
              <div className="dashboard_container">
                <div className="dashboard_box">
                  <div className="row m-l-0">
                    <div className="dashboard_content l-bg-cyan col-4">
                    <>
                          <h1 className="text-dark m-0 mt-3">{contact || 0}</h1>
                          <h4 className="text-dark text-center">Contacts</h4>
                        </>
                    </div>
                    <div className="dashboard_content l-bg-cyan col-4">
                    <>
                          <h1 className="text-dark m-0 mt-3">
                            {stateSalary || 0}
                          </h1>
                          <h4 className="text-dark text-center">
                            State Salary
                          </h4>
                        </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* EMC */}
          <div className="row">
            <div className="dashboard_container">
              <div className="row m-l-0">
                <div className="d-flex align-items-center justify-content-between p-0">
                  <h5>EMC</h5>
                  <Link
                    to={"/employer-list"}
                    className={"label label-primary btn-sm"}
                  >
                    View {">> "}
                  </Link>
                </div>
                <div className="dashboard_content l-bg-orange-dark col-4">
                <>
                      <h1 className="text-dark m-0 mt-3">{emc?.today || 0}</h1>
                      <h4 className="text-dark text-center">Today</h4>
                    </>
                </div>
                <div className="dashboard_content l-bg-orange-dark col-4">
                <>
                      <h1 className="text-dark m-0 mt-3">
                        {emc?.this_month || 0}
                      </h1>
                      <h4 className="text-dark text-center">This Month</h4>
                    </>
                </div>
                <div className="dashboard_content l-bg-orange-dark col-4">
                <>
                      <h1 className="text-dark m-0 mt-3">{emc?.total || 0}</h1>
                      <h4 className="text-dark text-center">Total</h4>
                    </>
                </div>
                <div className="dashboard_content l-bg-orange-dark col-4">
                <>
                      <h1 className="text-dark m-0 mt-3">
                        {emc?.total_pending || 0}
                      </h1>
                      <h4 className="text-dark text-center">KYC Pending</h4>
                    </>
                </div>
                <div className="dashboard_content l-bg-orange-dark col-4">
                <>
                      <h1 className="text-dark m-0 mt-3">
                        {emc?.total_send_for_approval || 0}
                      </h1>
                      <h4 className="text-dark text-center">
                        KYC Send For Approval
                      </h4>
                    </>
                </div>
                <div className="dashboard_content l-bg-orange-dark col-4">
                <>
                      <h1 className="text-dark m-0 mt-3">
                        {emc?.total_approved || 0}
                      </h1>
                      <h4 className="text-dark text-center">KYC Approved</h4>
                    </>
                </div>
                <div className="dashboard_content l-bg-orange-dark col-4">
                <>
                      <h1 className="text-dark m-0 mt-3">
                        {emc?.total_rejected || 0}
                      </h1>
                      <h4 className="text-dark text-center">KYC Rejected</h4>
                    </>
                </div>
              </div>
            </div>
          </div>

          {/* ERM */}
          <div className="row">
            <div className="dashboard_container">
              <div className="row m-l-0">
                <div className="d-flex align-items-center justify-content-between p-0">
                  <h5>ERM</h5>
                  <Link
                    to={"/erm-list"}
                    className={"label label-primary btn-sm"}
                  >
                    View {">> "}
                  </Link>
                </div>
                <div className="dashboard_content l-bg-green-dark col-4">
                <>
                      <h1 className="text-dark m-0 mt-3">{erm?.today || 0}</h1>
                      <h4 className="text-dark text-center">Today</h4>
                    </>
                </div>
                <div className="dashboard_content l-bg-green-dark col-4">
                <>
                      <h1 className="text-dark m-0 mt-3">
                        {erm?.this_month || 0}
                      </h1>
                      <h4 className="text-dark text-center">This Month</h4>
                    </>
                </div>
                <div className="dashboard_content l-bg-green-dark col-4">
                <>
                      <h1 className="text-dark m-0 mt-3">{erm?.total || 0}</h1>
                      <h4 className="text-dark text-center">Total</h4>
                    </>
                </div>
                <div className="dashboard_content l-bg-green-dark col-4">
                <>
                      <h1 className="text-dark m-0 mt-3">
                        {erm?.total_pending || 0}
                      </h1>
                      <h4 className="text-dark text-center">KYC Pending</h4>
                    </>
                </div>
                <div className="dashboard_content l-bg-green-dark col-4">
                <>
                      <h1 className="text-dark m-0 mt-3">
                        {erm?.total_send_for_approval || 0}
                      </h1>
                      <h4 className="text-dark text-center">
                        KYC Send For Approval
                      </h4>
                    </>
                </div>
                <div className="dashboard_content l-bg-green-dark col-4">
                <>
                      <h1 className="text-dark m-0 mt-3">
                        {erm?.total_approved || 0}
                      </h1>
                      <h4 className="text-dark text-center">KYC Approved</h4>
                    </>
                </div>
                <div className="dashboard_content l-bg-green-dark col-4">
                <>
                      <h1 className="text-dark m-0 mt-3">
                        {erm?.total_rejected || 0}
                      </h1>
                      <h4 className="text-dark text-center">KYC Rejected</h4>
                    </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default Home;
