import WebLayout from "../../layout/WebLayout";
import { useCallback, useEffect, useState } from "react";
import AddCertification from "../../components/AddCertification";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import WebAction from "../../stores/action/webAction";
import Pagination from "../../components/Pagination";
import TableLoader from "../../components/TableLoader";
import Breadcrumb from "../../components/Breadcrumb";
import FilterSorting from "../../components/FilterSorting";

function Certification() {
  const dispatch = useDispatch<any>();
  const { getCertificationList, lastPage, totalData, from, to } = useSelector(
    (state: RootState) => state.web,
  );

  const [catModal, setCatModal] = useState<boolean>(false);
  const [modelType, setModelType] = useState<any>("add");
  const [editableData, setEditableData] = useState<string>("add");
  const [search, setSearch] = useState<any>();
  const [limit, setLimit] = useState<any>(10);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);

  const modalOpen = (type: string) => {
    setCatModal(true);
    setModelType(type);
  };

  const maxPagesToShow = 5;
  const pagination = [];

  const startPage = Math.max(page - Math.floor(maxPagesToShow / 2), 1);
  const endPage = Math.min(startPage + maxPagesToShow - 1, lastPage);

  for (let i = startPage; i <= endPage; i++) {
    pagination.push(i);
  }

  const getData = useCallback(async () => {
    setLoading(true);
    try {
      await dispatch(
        WebAction.getCertification({
          limit: limit,
          search: search,
          page: page,
        }),
      );
    } finally {
      setLoading(false);
    }
  }, [dispatch, limit, search, page]);

  useEffect(() => {
    getData();
  }, [getData, search, limit, page]);

  const deleteData = async (id: number) => {
    await dispatch(WebAction.deleteCertificate({ id: id }));
    getData();
  };

  return (
    <WebLayout pageName={"Certification"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <Breadcrumb
                mainTitle={"Setting"}
                subTitle={"Certification List"}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <div className="header">
                  <h2>CERTIFICATION LIST</h2>
                  <div className="header-dropdown m-r--5">
                    {catModal && (
                      <AddCertification
                        setCatModal={setCatModal}
                        catModal={catModal}
                        type={modelType}
                        editableData={editableData}
                        getData={getData}
                      />
                    )}
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={() => modalOpen("add")}
                    >
                      ADD CERTIFICATION
                    </button>
                  </div>
                </div>
                <FilterSorting setLimit={setLimit} setSearch={setSearch} />
                <div className="body">
                  <div className="table-responsive">
                    <table
                      id="basicTable"
                      className="table table-hover table-checkable order-column contact_list"
                    >
                      <thead>
                        <tr>
                          <th className="center">#</th>
                          <th className="center">Title</th>
                          <th className="center">PDF</th>
                          <th className="center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <TableLoader column={4} />
                        ) : getCertificationList?.length > 0 ? (
                          getCertificationList.map(
                            (dataList: any, index: number) => {
                              return (
                                <tr key={index}>
                                  <td>{from + index}</td>
                                  <td>{dataList.title}</td>
                                  <td>
                                    <iframe
                                      src={dataList.image}
                                      title={dataList.title}
                                    />
                                  </td>
                                  <td>
                                    <button
                                      onClick={() => {
                                        modalOpen("update");
                                        setEditableData(dataList);
                                      }}
                                      className={"btn btn-success btn-sm"}
                                    >
                                      Edit
                                    </button>
                                    <button
                                      onClick={() => deleteData(dataList.id)}
                                      className={"btn btn-danger btn-sm m-l-10"}
                                    >
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              );
                            },
                          )
                        ) : (
                          <tr>
                            <td colSpan={4} className="text-center">
                              No matching records found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {getCertificationList?.length > 0 ? (
                    <Pagination
                      from={from}
                      to={to}
                      totalData={totalData}
                      setPage={setPage}
                      page={page}
                      pagination={pagination}
                      lastPage={lastPage}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default Certification;
