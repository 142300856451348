import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { ADMIN_TOKEN, LOGO } from "../../utils/Constants";
import AuthAction from "../../stores/action/authAction";
import toast from "react-hot-toast";

type FormData = {
  email: string;
  password: string;
};

function Login() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState<any>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { register, handleSubmit, formState } = useForm<FormData>();
  const dispatch = useDispatch()<any>;
  const { authenticated, token } = useSelector(
    (state: RootState) => state.auth,
  );
  const page = useNavigate();

  useEffect(() => {
    const tokens = localStorage.getItem(ADMIN_TOKEN);
    if ((authenticated && token) || tokens) {
      page("/dashboard");
    }
  }, [authenticated, page, token]);

  const { isSubmitting } = formState;
  const onSubmit = handleSubmit(async (data) => {
    try {
      const response = await dispatch(
        AuthAction.logIn({
          email: data.email,
          password: data.password,
        }),
      );

      if (response?.error) {
        setErrorMessage(response.error);
        setTimeout(() => {
          setErrorMessage(null);
        }, 2000);
      } else {
        setErrorMessage(null);
        toast.success("Login Successful!");
        navigate("/dashboard");
      }
    } catch (error) {
      setTimeout(() => {
        setErrorMessage("Something went wrong. Please try again.");
      }, 2000);
    }
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <React.Fragment>
      <link href={"./assets/css/pages/extra_pages.css"} rel="stylesheet" />
      <div className="container-login100">
        <div className="login-left-side">
          <img
            src={"./assets/images/login_bg.jpg"}
            alt="Login Background"
            className="login-image"
          />
        </div>
        <div className="login-right-side">
          <div className="login-form-container">
            <form
              className="login100-form validate-form text-center"
              onSubmit={onSubmit}
            >
              <img src={LOGO} alt="ASL Logo" />
              <h2 className="text-center m-3">Welcome Back!</h2>
              {errorMessage && <p className="error-message">{errorMessage}</p>}
              <div className="wrap-input100 validate-input">
                <input
                  className="input100 styled-input"
                  type="email"
                  {...register("email")}
                  placeholder="Email"
                  required={true}
                />
              </div>
              <div className="wrap-input100 validate-input">
                <input
                  className="input100 styled-input"
                  type={showPassword ? "text" : "password"}
                  {...register("password")}
                  placeholder="Password"
                  required={true}
                />
                <span
                  onClick={togglePasswordVisibility}
                  className="password-toggle"
                >
                  {showPassword ? (
                    <i className="fa fa-eye-slash" />
                  ) : (
                    <i className="fa fa-eye" />
                  )}
                </span>
              </div>
              <div className="container-login100-form-btn">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="button-50"
                >
                  {isSubmitting && (
                    <span className="fa fa-spinner fa-spin mr-1"></span>
                  )}
                  &nbsp;LOGIN
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Login;
