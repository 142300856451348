import { Link, useParams } from "react-router-dom";
import WebLayout from "../../layout/WebLayout";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { RootState } from "../../stores";
import { useDispatch, useSelector } from "react-redux";
import EmployerAction from "../../stores/action/employerAction";
import Pagination from "../../components/Pagination";
import Breadcrumb from "../../components/Breadcrumb";
import moment from "moment";

function EMCEmployeePaymentList() {
  const { id, empNo, cName }: any = useParams<any>();

  const [search, setSearch] = useState<string>("");
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const dispatch = useDispatch<any>();

  const { emcEmployeePaymentList, lastPage, totalData, from, to } = useSelector(
    (state: RootState) => state.employer,
  );

  const maxPagesToShow = 5;

  const pagination = useMemo(() => {
    const startPage = Math.max(page - Math.floor(maxPagesToShow / 2), 1);
    const endPage = Math.min(startPage + maxPagesToShow - 1, lastPage);
    return Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i,
    );
  }, [lastPage, page]);

  const fetchEMCEmployeePaymentList = useCallback(async () => {
    await dispatch(
      EmployerAction.getEMCEmployeePaymentList({
        search,
        limit,
        page,
        paymentId: id,
      }),
    );
  }, [dispatch, search, limit, page]);

  useEffect(() => {
    fetchEMCEmployeePaymentList();
  }, [fetchEMCEmployeePaymentList]);

  return (
    <WebLayout pageName="EMCEmployeePaymentList">
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <Breadcrumb
                mainTitle={"Manage Salary"}
                subTitle={"EMC Employee Payment List"}
              />
            </div>
          </div>
          <div className="card">
            <div className="g-3 px-4 mt-3 d-flex justify-content-between align-items-end">
              <div className="col-md-3">
                <label className="mb-2">Show Entries</label>
                <select
                  value={limit}
                  onChange={(e) => setLimit(Number(e.target.value))}
                  id="defaultSelect"
                  className="form-select"
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div className="col-md-6"></div>
              <div className="col-md-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                />
              </div>
            </div>
            <div className="g-3 px-4 mt-3 row">
              <div className={"col-md-12"}>
                <h6>
                  {cName} ({empNo})
                </h6>
              </div>
            </div>
            <div className="body">
              <div className="table-responsive">
                <table className="table table-hover table-checkable">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Payment For This Month</th>
                      <th>Employee Information</th>
                      <th>Salary Information</th>
                      <th>Service & GST</th>
                      <th>Amount Receivable</th>
                      <th>Distribution</th>
                      <th>Pension Fund & PP</th>
                    </tr>
                  </thead>
                  <tbody>
                    {emcEmployeePaymentList.length > 0 ? (
                      emcEmployeePaymentList.map(
                        (dataList: any, index: number) => (
                          <tr key={index}>
                            <td className="center">{index + 1}</td>
                            <td className="center">
                              {moment(dataList?.payment_month, "MM").format(
                                "MMMM",
                              )}
                            </td>
                            <td className="center">
                              <b>EMA Id : </b> {dataList?.ema_id}
                              <br />
                              <b>Name : </b> {dataList?.cName}
                              <br />
                              <b>Email : </b> {dataList?.cEmail}
                            </td>
                            <td className="center">
                              <b>Gross Salary : </b> Rs.
                              {dataList?.gross_salary} <br />
                              <b>Salary : </b> Rs.
                              {dataList?.salary}
                            </td>
                            <td className="center">
                              <b>Service Fee : </b> Rs.
                              {dataList?.service_fee} <br />
                              <b>GST Rate : </b>
                              {dataList?.gst_rate}% <br />
                              <b>GST Amount : </b> Rs.
                              {dataList?.gst_amount}
                            </td>
                            <td className="center">
                              Rs.
                              {dataList?.amount_receivable}
                            </td>
                            <td>
                              <b>Distributed Rate : </b>
                              {dataList?.margin_distributed_in_percentage}%
                              <br />
                              <b>Distributed Margin : </b> Rs.
                              {dataList?.distributed_margin}
                            </td>
                            <td>
                              <b>Pension Fund : </b> Rs.
                              {dataList?.pension_fund} <br />
                              <b>PP : </b>
                              {dataList?.pv}
                            </td>
                          </tr>
                        ),
                      )
                    ) : (
                      <tr>
                        <td colSpan={10} className="text-center">
                          No matching records found!
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {emcEmployeePaymentList?.length > 0 && (
                <Pagination
                  from={from}
                  to={to}
                  totalData={totalData}
                  setPage={setPage}
                  page={page}
                  pagination={pagination}
                  lastPage={lastPage}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default EMCEmployeePaymentList;
