const Pagination = ({
  from,
  to,
  totalData,
  setPage,
  page,
  pagination,
  lastPage,
}: any) => {
  return (
    <div className="row mt-2 justify-content-between">
      <div className="col-md-auto me-auto ">
        <div className="dt-info">
          Showing {from} to {to} of {totalData} entries
        </div>
      </div>
      <div className="col-md-auto ms-auto ">
        <div className="dt-paging paging_full_numbers">
          <ul className="pagination">
            <li
              style={{ cursor: "pointer" }}
              className={`dt-paging-button page-item ${page === 1 ? "disabled" : ""}`}
              onClick={() => setPage(1)}
            >
              <p className="page-link first">
                <span aria-hidden="true">First</span>
              </p>
            </li>
            <li
              style={{ cursor: "pointer" }}
              className={`dt-paging-button page-item prev ${
                page === 1 ? "disabled" : ""
              }`}
              onClick={() => (page === 1 ? "" : setPage(page - 1))}
            >
              <p className="page-link previous">Previous</p>
            </li>
            {pagination.map((p: any, index: number) => {
              return (
                <li
                  key={index}
                  style={{ cursor: "pointer" }}
                  className={`dt-paging-button page-item ${
                    page === p ? "active" : ""
                  }`}
                  onClick={() => setPage(p)}
                >
                  <p className="page-link">{p}</p>
                </li>
              );
            })}

            <li
              style={{ cursor: "pointer" }}
              className={`dt-paging-button page-item next ${
                lastPage === page ? "disabled" : ""
              }`}
              onClick={() => (lastPage === page ? "" : setPage(page + 1))}
            >
              <p className="page-link next">Next</p>
            </li>
            <li
              style={{ cursor: "pointer" }}
              className={`dt-paging-button page-item ${page === lastPage ? "disabled" : ""}`}
              onClick={() => setPage(lastPage)}
            >
              <p className="page-link last">
                <span aria-hidden="true">Last</span>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Pagination;
