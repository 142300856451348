import { Route, Routes } from "react-router-dom";
import Home from "../views/dashboard/Home";
import Login from "../views/auth/Login";
import AboutUs from "../views/about_us/AboutUs";
import Certification from "../views/certification/Certification";
import Download from "../views/download/Download";
import ContactList from "../views/enquiry/ContactList";
import EnquiryList from "../views/enquiry/EnquiryList";
import EmployerList from "../views/employer/EmployerList";
import CompanyType from "../views/company_type/CompanyType";
import EmployeeList from "../views/employee/EmployeeList";
import AttendanceList from "../views/attendance/AttendanceList";
import EmployeeWiseAttendanceList from "../views/attendance/EmployeeWiseAttendanceList";
import EMCPaymentList from "../views/salary/EMCPaymentList";
import StateWiseSalary from "../views/state_wise_salary/StateWiseSalary";
import ERMList from "../views/erm/ERMList";
import OfferPlan from "../views/offer_plan/OfferPlan";
import Category from "../views/offer_plan/Category";
import EMCEmployeePaymentList from "../views/salary/EMCEmployeePaymentList";
import PvHistoryList from "../views/pv_history/PvHistoryList";

function PageRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/dashboard" element={<Home />} />
      <Route path="/enquiry-list" element={<EnquiryList />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/certification" element={<Certification />} />
      <Route path="/download" element={<Download />} />
      <Route path="/contact-list" element={<ContactList />} />
      <Route path="/employer-list" element={<EmployerList />} />
      <Route path="/erm-list" element={<ERMList />} />
      <Route path="/company-type" element={<CompanyType />} />
      <Route path="/employee-list" element={<EmployeeList />} />
      <Route path="/attendance-list" element={<AttendanceList />} />
      <Route path="/payment-list" element={<EMCPaymentList />} />
      <Route
        path="/payment-employee-list/:id/:empNo/:cName"
        element={<EMCEmployeePaymentList />}
      />
      <Route path="/pv-history" element={<PvHistoryList />} />
      <Route
        path="/ema-wise-attendance-list/:id"
        element={<EmployeeWiseAttendanceList />}
      />
      <Route path="/state-wise-salary" element={<StateWiseSalary />} />
      <Route path="/plan-category/:offerType" element={<Category />} />
      <Route path="/policy-category/:offerType" element={<Category />} />
      <Route path="/faq-category/:offerType" element={<Category />} />
      <Route path="/plan-offer-plan/:id" element={<OfferPlan />} />
      <Route path="/policy-offer-plan/:id" element={<OfferPlan />} />
      <Route path="/faq-offer-plan/:id" element={<OfferPlan />} />
    </Routes>
  );
}

export default PageRoutes;
