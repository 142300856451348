import React from "react";
import { Link } from "react-router-dom";
import WebLayout from "../../layout/WebLayout";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import EmployeeAction from "../../stores/action/employeeAction";
import UpdateKYCModal from "../../components/employee/UpdateKYCModal";
import ApprovedKYCModal from "../../components/employee/ApprovedKYCModal";
import RightSideBarModal from "../../components/employee/RightSidebarModal";
import ToggleTab from "../../components/employee/ToggleTab";
import Pagination from "../../components/Pagination";
import Breadcrumb from "../../components/Breadcrumb";

function EmployeeList() {
  const [search, setSearch] = useState<any>("");
  const [limit, setLimit] = useState<any>(10);
  const [page, set_page] = useState<any>(1);
  const [toggle, setToggle] = useState<boolean>(false);
  const [kycModal, setKYCModal] = useState<boolean>(false);
  const [viewData, setViewData] = useState<any>();
  const [type, setType] = useState<any>();
  const [approveKYCModal, setApproveKYCModal] = useState<any>();
  const [editableData, setEditableData] = useState<any>([]);
  const dispatch = useDispatch<any>();

  const {
    employeeList,
    lastPage,
    totalData,
    from,
    to,
    totalApprovedKYC,
    totalPendingKYC,
  } = useSelector((state: RootState) => state.employee);

  const maxPagesToShow = 5;
  const pagination = [];

  const startPage = Math.max(page - Math.floor(maxPagesToShow / 2), 1);
  const endPage = Math.min(startPage + maxPagesToShow - 1, lastPage);

  for (let i = startPage; i <= endPage; i++) {
    pagination.push(i);
  }

  const getEmployeeData = useCallback(async () => {
    await dispatch(
      EmployeeAction.getEmployeeList({
        limit: limit,
        search: search,
        page: page,
        type: type,
      }),
    );
  }, [dispatch, limit, search, page, type]);

  useEffect(() => {
    getEmployeeData();
  }, [getEmployeeData, search, limit, page, type]);

  const toggleOpen = (id: any) => {
    setToggle(id);
  };

  const toggleClose = (id: any) => {
    setToggle(false);
  };

  const oepnUpdateKYCModal = (id: any) => {
    setKYCModal(true);
  };

  const openApproveKYCModal = (id: any) => {
    setApproveKYCModal(true);
  };

  return (
    <WebLayout pageName={"EmployeeList"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <Breadcrumb mainTitle={"ASL"} subTitle={"ASL List"} />
            </div>
          </div>
          <RightSideBarModal editableData={editableData} />
          <UpdateKYCModal
            modal={kycModal}
            setModal={setKYCModal}
            getEmployeeData={getEmployeeData}
            editableData={editableData}
          />
          <ApprovedKYCModal
            modal={approveKYCModal}
            setModal={setApproveKYCModal}
            getEmployeeData={getEmployeeData}
            editableData={editableData}
          />
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <div className="header">
                  <button
                    className="btn btn-success"
                    onClick={() => setType("VERIFIED")}
                  >
                    KYC Approved List{" "}
                    <span className="badge">{totalApprovedKYC}</span>
                  </button>{" "}
                  &nbsp;&nbsp;
                  <button
                    className="btn btn-danger"
                    onClick={() => setType("NOT VERIFIED")}
                  >
                    KYC Pending List{" "}
                    <span className="badge">{totalPendingKYC}</span>
                  </button>
                </div>
                <div
                  className="row g-3 px-4 mb-2 d-flex
              justify-content-between
              align-items-end"
                >
                  <div className="col-md-3">
                    <label className="mb-2">Show Entries</label>
                    <select
                      onChange={(e) => setLimit(e.target.value)}
                      id="defaultSelect"
                      className="form-select"
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <div className="col-md-6"></div>
                  <div className="col-md-3">
                    <div className="input-group input-group-merge mb-1">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="body">
                  <div className="table-responsive">
                    <table
                      id="basicTable"
                      className="table table-hover table-checkable order-column contact_list"
                    >
                      <thead>
                        <tr>
                          <th className="center"></th>
                          <th className="center">#</th>
                          <th className="center">EMP ID</th>
                          <th className="center">Company Name</th>
                          <th className="center">Name</th>
                          <th className="center">Email</th>
                          <th className="center">Mobile</th>
                          <th className="center">Account Status</th>
                          <th className="center">KYC Status</th>
                          <th className="center">Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {employeeList?.length > 0 ? (
                          employeeList.map((dataList: any, ind: number) => {
                            return (
                              <React.Fragment key={dataList.id || ind}>
                                <tr>
                                  <td className="center">
                                    <Link
                                      to="#"
                                      onClick={() => {
                                        setViewData(dataList);
                                        toggleOpen(dataList.id);
                                        toggle
                                          ? toggleClose(dataList.id)
                                          : toggleOpen(dataList.id);
                                      }}
                                    >
                                      <i
                                        className={
                                          toggle
                                            ? "fa fa-minus-circle text-danger"
                                            : "fas fa-plus-circle text-success"
                                        }
                                      ></i>
                                    </Link>
                                  </td>
                                  <td className={"center"}>{ind + 1}</td>
                                  <td className={"center"}>
                                    {dataList.emp_no}
                                  </td>
                                  <td className={"center"}>
                                    {dataList.companyName}
                                  </td>
                                  <td className={"center"}>{dataList.name}</td>
                                  <td className={"center"}>{dataList.email}</td>
                                  <td className={"center"}>
                                    {dataList.mobile}
                                  </td>
                                  <td className={"center"}>
                                    {dataList?.employee_status === "PENDING" ? (
                                      <Link
                                        to="#"
                                        className="label label-warning rounded-0"
                                      >
                                        {dataList.employee_status}
                                      </Link>
                                    ) : dataList?.employee_status ===
                                      "ACTIVE" ? (
                                      <Link
                                        to="#"
                                        className="label label-success rounded-0"
                                      >
                                        {dataList.employee_status}
                                      </Link>
                                    ) : dataList?.employee_status ===
                                      "INACTIVE" ? (
                                      <Link
                                        to="#"
                                        className="label label-danger rounded-0"
                                      >
                                        {dataList.employee_status}
                                      </Link>
                                    ) : null}
                                  </td>
                                  <td className={"center"}>
                                    {dataList?.kyc_status === "VERIFIED" ? (
                                      <Link
                                        to="#"
                                        className="label label-success rounded-0"
                                      >
                                        {dataList.kyc_status}
                                      </Link>
                                    ) : dataList?.kyc_status ===
                                      "SEND FOR APPROVAL" ? (
                                      <Link
                                        to="#"
                                        onClick={() => {
                                          setEditableData(dataList);
                                          openApproveKYCModal(dataList.id);
                                        }}
                                        className="label label-warning rounded-0"
                                      >
                                        {dataList.kyc_status}
                                      </Link>
                                    ) : dataList?.kyc_status ===
                                      "NOT VERIFIED" ? (
                                      <Link
                                        to={"#"}
                                        className="label label-danger rounded-0"
                                        onClick={() => {
                                          setEditableData(dataList);
                                          oepnUpdateKYCModal(dataList.id);
                                        }}
                                      >
                                        {dataList.kyc_status}
                                      </Link>
                                    ) : dataList?.kyc_status === "REJECTED" ? (
                                      <Link
                                        to="#"
                                        className="label label-danger rounded-0"
                                      >
                                        {dataList.kyc_status}
                                      </Link>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td className={"center"}>
                                    <div className="d-flex">
                                      <Link
                                        to={"#"}
                                        className="label label-primary rounded-0"
                                        type="button"
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#offcanvasRight"
                                        onClick={() => {
                                          setEditableData(dataList);
                                        }}
                                        aria-controls="offcanvasRight"
                                      >
                                        <i
                                          className="fa fa-eye"
                                          style={{ fontSize: "14px" }}
                                        ></i>{" "}
                                        View
                                      </Link>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan={10}>
                                    <ToggleTab
                                      toggle={toggle}
                                      viewData={viewData}
                                    />
                                  </td>
                                </tr>
                              </React.Fragment>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={10}>No matching records found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {employeeList?.length > 0 ? (
                    <Pagination
                      from={from}
                      to={to}
                      totalData={totalData}
                      setPage={set_page}
                      page={page}
                      pagination={pagination}
                      lastPage={lastPage}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default EmployeeList;
