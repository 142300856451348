import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import axios from "axios";
import { ADMIN_TOKEN, BASE_URL } from "../../utils/Constants";
import LogoutAction from "./LogoutAction";
import DashboardType from "../type/dashboardType";

interface Action {
  type: string;
  payload?: any;
  token?: any;
}

const DashboardAction = {
  getContactCount:
    (): ThunkAction<void, RootState, null, Action> => async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.get(BASE_URL + "dashboard/contact", {
          headers: {
            Authorization: "Bearer " + JSON.parse(token),
            "Content-Type": "application/json",
          },
        });

        dispatch({
          type: DashboardType.SET_CONTACT_COUNT,
          payload: result.data.data,
        });
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },
  getEMCCount:
    (): ThunkAction<void, RootState, null, Action> => async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.get(BASE_URL + "dashboard/emc", {
          headers: {
            Authorization: "Bearer " + JSON.parse(token),
            "Content-Type": "application/json",
          },
        });

        dispatch({
          type: DashboardType.SET_EMC_COUNT,
          payload: result.data.data,
        });
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },
  getERMCount:
    (): ThunkAction<void, RootState, null, Action> => async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.get(BASE_URL + "dashboard/erm", {
          headers: {
            Authorization: "Bearer " + JSON.parse(token),
            "Content-Type": "application/json",
          },
        });

        dispatch({
          type: DashboardType.SET_ERM_COUNT,
          payload: result.data.data,
        });
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },
  getStateSalaryCount:
    (): ThunkAction<void, RootState, null, Action> => async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.get(
          BASE_URL + "dashboard/state_wise_salary",
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "application/json",
            },
          },
        );

        dispatch({
          type: DashboardType.SET_STATE_SALARY,
          payload: result.data.data,
        });
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },
};

export default DashboardAction;
