import {
  applyMiddleware,
  combineReducers,
  legacy_createStore as createStore,
} from "redux";
import thunk from "redux-thunk";

import AuthReducer from "./reducers/authReducers";
import WebReducers from "./reducers/webReducers";
import EnquiryReducers from "./reducers/enquiryReducers";
import EmployerReducers from "./reducers/employerReducers";
import EmployeeReducers from "./reducers/employeeReducers";
import AttendanceReducers from "./reducers/attendanceReducers";
import StateWiseSalaryReducers from "./reducers/stateWiseSalaryReducers";
import ERMReducers from "./reducers/ermReducers";
import DashboardReducers from "./reducers/dashboardReducers";
import PvReducers from "./reducers/pvReducers";

const rootReducer = combineReducers({
  auth: AuthReducer,
  enquiry: EnquiryReducers,
  web: WebReducers,
  employer: EmployerReducers,
  employee: EmployeeReducers,
  attendance: AttendanceReducers,
  stateWiseSalary: StateWiseSalaryReducers,
  erm: ERMReducers,
  dashboard: DashboardReducers,
  pv: PvReducers,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export type RootState = ReturnType<typeof rootReducer>;

export default store;
