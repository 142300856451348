import { Link } from "react-router-dom";
import EMCKYCApprovalModal from "./EMCKYCApprovalModal";
import React, { useRef, useState } from "react";

function EMCDetailsModal({ editableData, getEMCList }: any) {
  const closeButtonRef = useRef<HTMLButtonElement | null>(null);

  const [modal, setModal] = useState(false);

  const handleClose = () => {
    closeButtonRef.current?.dispatchEvent(
      new MouseEvent("click", { bubbles: true }),
    );
  };

  return (
    <div
      className="offcanvas offcanvas-end"
      tabIndex={-1}
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
    >
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="offcanvasRightLabel">
          EMC DETAILS
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          ref={closeButtonRef}
        />
      </div>
      <div style={{ marginLeft: 10 }}>
        {editableData?.kyc_status === "SEND FOR APPROVAL" ||
        editableData?.kyc_status === "REJECTED" ? (
          <button className={"btn btn-primary"} onClick={() => setModal(true)}>
            KYC ACTION
          </button>
        ) : null}
        {editableData?.kyc_status === "REJECTED" && (
          <>
            <p className={"mb-0 mt-2 text-danger font-bold"}>KYC Rejected</p>
            <p className={"mb-0 text-danger"}>
              Reason : {editableData?.kyc_reason}
            </p>
          </>
        )}
      </div>
      <div className="offcanvas-body p-0">
        <ul className="nav nav-tabs tab-nav-right" role="tablist">
          <li role="presentation">
            <Link to="#personal" data-bs-toggle="tab" className="active">
              Personal Details
            </Link>
          </li>
          <li role="presentation">
            <Link to="#bank" data-bs-toggle="tab">
              Bank Details
            </Link>
          </li>
          <li role="presentation">
            <Link to="#kyc" data-bs-toggle="tab">
              KYC Details
            </Link>
          </li>
        </ul>
        <div className="tab-content">
          <div role="tabpanel" className="tab-pane active" id="personal">
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <th>EMC Id</th>
                  <td>{editableData?.emp_no || "N/A"}</td>
                </tr>
                <tr>
                  <th>Company Logo</th>
                  <td>
                    {editableData?.company_image ? (
                      <Link to={editableData?.company_image} target={"_blank"}>
                        <img
                          src={editableData?.company_image}
                          className="mb-2"
                          style={{ width: 150, height: 150 }}
                          alt={"Company Logo"}
                        />
                      </Link>
                    ) : (
                      "N/A"
                    )}
                  </td>
                </tr>
                <tr>
                  <th>Company Name</th>
                  <td>{editableData?.company_name || "N/A"}</td>
                </tr>
                <tr>
                  <th>Company Type</th>
                  <td>{editableData?.companyTypeName || "N/A"}</td>
                </tr>
                <tr>
                  <th>No. Of Employee</th>
                  <td>{editableData?.no_of_employee || "N/A"}</td>
                </tr>
                <tr>
                  <th>Representative Name</th>
                  <td>{editableData?.name || "N/A"}</td>
                </tr>
                <tr>
                  <th>Email</th>
                  <td>{editableData?.email || "N/A"}</td>
                </tr>
                <tr>
                  <th>Mobile</th>
                  <td>{editableData?.mobile || "N/A"}</td>
                </tr>
                <tr>
                  <th>State</th>
                  <td>{editableData?.sName || "N/A"}</td>
                </tr>
                <tr>
                  <th>District</th>
                  <td>{editableData?.dName || "N/A"}</td>
                </tr>
                <tr>
                  <th>Address</th>
                  <td>{editableData?.address || "N/A"}</td>
                </tr>
                <tr>
                  <th>Pin Code</th>
                  <td>{editableData?.pincode || "N/A"}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div role="tabpanel" className="tab-pane" id="bank">
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <th>Account Holder Name</th>
                  <td>{editableData?.account_holder_name || "N/A"}</td>
                </tr>
                <tr>
                  <th>Account Number</th>
                  <td>{editableData?.account_number || "N/A"}</td>
                </tr>
                <tr>
                  <th>Bank Name</th>
                  <td>{editableData?.bank_name || "N/A"}</td>
                </tr>
                <tr>
                  <th>IFSC Code</th>
                  <td>{editableData?.ifsc_code || "N/A"}</td>
                </tr>
                <tr>
                  <th>Branch</th>
                  <td>{editableData?.branch_name || "N/A"}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div role="tabpanel" className="tab-pane" id="kyc">
            <table className="table table-bordered">
              {editableData?.companyTypeName === "Proprietorship Firm" ? (
                <tbody>
                  <tr>
                    <th>
                      {editableData?.gst_img ? (
                        <>
                          <span>
                            <b>GST Image</b>
                          </span>{" "}
                          <br />
                          <Link to={editableData?.gst_img} target={"_blank"}>
                            <img
                              src={editableData?.gst_img}
                              className="mb-2"
                              style={{ width: 150, height: 150 }}
                              alt={"GST"}
                            />
                          </Link>
                          <br />
                        </>
                      ) : null}
                      <span>
                        GST Number
                        {editableData?.gst_no || "N/A"}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      {editableData?.pancard_img ? (
                        <>
                          <span>
                            <b>PAN Image</b>
                          </span>{" "}
                          <br />
                          <Link
                            to={editableData?.pancard_img}
                            target={"_blank"}
                          >
                            <img
                              src={editableData?.pancard_img}
                              className="mb-2"
                              style={{ width: 150, height: 150 }}
                              alt={"PAN Card"}
                            />
                          </Link>
                          <br />
                        </>
                      ) : null}
                      <span>
                        <b>PAN Number:</b> {editableData?.pancard_no || "N/A"}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      {editableData?.msme_img ? (
                        <>
                          <span>
                            <b>MSME Image</b>
                          </span>{" "}
                          <br />
                          <Link to={editableData?.msme_img} target={"_blank"}>
                            <img
                              src={editableData?.msme_img}
                              className="mb-2"
                              style={{ width: 150, height: 150 }}
                              alt={"MSME"}
                            />
                          </Link>
                          <br />
                        </>
                      ) : null}
                      <span>
                        <b>MSME Number:</b> {editableData?.msme_no || "N/A"}
                      </span>
                    </th>
                  </tr>
                </tbody>
              ) : editableData?.companyTypeName === "Partnership Firm" ? (
                <tbody>
                  <tr>
                    <th>
                      {editableData?.gst_img ? (
                        <>
                          <span>
                            <b>GST Image</b>
                          </span>{" "}
                          <br />
                          <Link to={editableData?.gst_img} target={"_blank"}>
                            <img
                              src={editableData?.gst_img}
                              className="mb-2"
                              style={{ width: 150, height: 150 }}
                              alt={"Agreement"}
                            />
                          </Link>
                          <br />
                        </>
                      ) : null}
                      <span>
                        <b>GST Number:</b> {editableData?.gst_no || "N/A"}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      {editableData?.pancard_img ? (
                        <>
                          <span>
                            <b>PAN Image</b>
                          </span>{" "}
                          <br />
                          <Link
                            to={editableData?.pancard_img}
                            target={"_blank"}
                          >
                            <img
                              src={editableData?.pancard_img}
                              className="mb-2"
                              style={{ width: 150, height: 150 }}
                              alt={"PAN Card"}
                            />
                          </Link>
                          <br />
                        </>
                      ) : null}
                      <span>
                        <b>PAN Number:</b> {editableData?.pancard_no || "N/A"}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      {editableData?.partnership_img ? (
                        <>
                          <span>
                            <b>Partnership Image</b>
                          </span>{" "}
                          <br />
                          <Link
                            to={editableData?.partnership_img}
                            target={"_blank"}
                          >
                            <img
                              src={editableData?.partnership_img}
                              className="mb-2"
                              style={{ width: 150, height: 150 }}
                              alt={"Partnership"}
                            />
                          </Link>
                          <br />
                        </>
                      ) : null}
                      <span>
                        <b>Partnership Number:</b>{" "}
                        {editableData?.partnership_no || "N/A"}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      {editableData?.msme_img ? (
                        <>
                          <span>
                            <b>MSME Image</b>
                          </span>{" "}
                          <br />
                          <Link to={editableData?.msme_img} target={"_blank"}>
                            <img
                              src={editableData?.msme_img}
                              className="mb-2"
                              style={{ width: 150, height: 150 }}
                              alt={"MSME"}
                            />
                          </Link>
                          <br />
                        </>
                      ) : null}
                      <span>
                        <b>MSME Number:</b> {editableData?.msme_no || "N/A"}
                      </span>
                    </th>
                  </tr>
                </tbody>
              ) : editableData?.companyTypeName === "LLP" ? (
                <tbody>
                  <tr>
                    <th>
                      {editableData?.gst_img ? (
                        <>
                          <span>
                            <b>GST Image</b>
                          </span>{" "}
                          <br />
                          <Link to={editableData?.gst_img} target={"_blank"}>
                            <img
                              src={editableData?.gst_img}
                              className="mb-2"
                              style={{ width: 150, height: 150 }}
                              alt={"Agreement"}
                            />
                          </Link>
                          <br />
                        </>
                      ) : null}
                      <span>
                        <b>GST Number:</b> {editableData?.gst_no || "N/A"}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      {editableData?.pancard_img ? (
                        <>
                          <span>
                            <b>PAN Image</b>
                          </span>{" "}
                          <br />
                          <Link
                            to={editableData?.pancard_img}
                            target={"_blank"}
                          >
                            <img
                              src={editableData?.pancard_img}
                              className="mb-2"
                              style={{ width: 150, height: 150 }}
                              alt={"PAN Card"}
                            />
                          </Link>
                          <br />
                        </>
                      ) : null}
                      <span>
                        <b>PAN Number:</b> {editableData?.pancard_no || "N/A"}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      {editableData?.rc_img ? (
                        <>
                          <span>
                            <b>RC Image</b>
                          </span>{" "}
                          <br />
                          <Link to={editableData?.rc_img} target={"_blank"}>
                            <img
                              src={editableData?.rc_img}
                              className="mb-2"
                              style={{ width: 150, height: 150 }}
                              alt={"RC"}
                            />
                          </Link>
                          <br />
                        </>
                      ) : null}
                      <span>
                        <b>RC Number:</b> {editableData?.rc_no || "N/A"}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      {editableData?.partnership_img ? (
                        <>
                          <span>
                            <b>Partnership Image</b>
                          </span>{" "}
                          <br />
                          <Link
                            to={editableData?.partnership_img}
                            target={"_blank"}
                          >
                            <img
                              src={editableData?.partnership_img}
                              className="mb-2"
                              style={{ width: 150, height: 150 }}
                              alt={"Partnership"}
                            />
                          </Link>
                          <br />
                        </>
                      ) : null}
                      <span>
                        <b>Partnership Number:</b>{" "}
                        {editableData?.partnership_no || "N/A"}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      {editableData?.msme_img ? (
                        <>
                          <span>
                            <b>MSME Image</b>
                          </span>{" "}
                          <br />
                          <Link to={editableData?.msme_img} target={"_blank"}>
                            <img
                              src={editableData?.msme_img}
                              className="mb-2"
                              style={{ width: 150, height: 150 }}
                              alt={"MSME"}
                            />
                          </Link>
                          <br />
                        </>
                      ) : null}
                      <span>
                        <b>MSME Number:</b> {editableData?.msme_no || "N/A"}
                      </span>
                    </th>
                  </tr>
                </tbody>
              ) : editableData?.companyTypeName === "Company" ? (
                <tbody>
                  <tr>
                    <th>
                      {editableData?.gst_img ? (
                        <>
                          <span>
                            <b>GST Image</b>
                          </span>{" "}
                          <br />
                          <Link to={editableData?.gst_img} target={"_blank"}>
                            <img
                              src={editableData?.gst_img}
                              className="mb-2"
                              style={{ width: 150, height: 150 }}
                              alt={"GST"}
                            />
                          </Link>
                          <br />
                        </>
                      ) : null}
                      <span>
                        <b>GST Number:</b> {editableData?.gst_no || "N/A"}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      {editableData?.pancard_img ? (
                        <>
                          <span>
                            <b>PAN Image</b>
                          </span>{" "}
                          <br />
                          <Link
                            to={editableData?.pancard_img}
                            target={"_blank"}
                          >
                            <img
                              src={editableData?.pancard_img}
                              className="mb-2"
                              style={{ width: 150, height: 150 }}
                              alt={"PAN Card"}
                            />
                          </Link>
                          <br />
                        </>
                      ) : null}
                      <span>
                        <b>PAN Number:</b> {editableData?.pancard_no || "N/A"}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      {editableData?.moa_img ? (
                        <>
                          <span>
                            <b>MOA Image</b>
                          </span>{" "}
                          <br />
                          <Link to={editableData?.moa_img} target={"_blank"}>
                            <img
                              src={editableData?.moa_img}
                              className="mb-2"
                              style={{ width: 150, height: 150 }}
                              alt={"MOA"}
                            />
                          </Link>
                          <br />
                        </>
                      ) : null}
                      <span>
                        <b>MOA Number:</b> {editableData?.moa_no || "N/A"}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      {editableData?.aoa_img ? (
                        <>
                          <span>
                            <b>AOA Image</b>
                          </span>{" "}
                          <br />
                          <Link to={editableData?.aoa_img} target={"_blank"}>
                            <img
                              src={editableData?.aoa_img}
                              className="mb-2"
                              style={{ width: 150, height: 150 }}
                              alt={"AOA"}
                            />
                          </Link>
                          <br />
                        </>
                      ) : null}
                      <span>
                        <b>AOA Number:</b> {editableData?.aoa_no || "N/A"}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      {editableData?.coi_img ? (
                        <>
                          <span>
                            <b>COI Image</b>
                          </span>{" "}
                          <br />
                          <Link to={editableData?.coi_img} target={"_blank"}>
                            <img
                              src={editableData?.coi_img}
                              className="mb-2"
                              style={{ width: 150, height: 150 }}
                              alt={"COI"}
                            />
                          </Link>
                          <br />
                        </>
                      ) : null}
                      <span>
                        <b>COI Number:</b> {editableData?.coi_no || "N/A"}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      {editableData?.msme_img ? (
                        <>
                          <span>
                            <b>MSME Image</b>
                          </span>{" "}
                          <br />
                          <Link to={editableData?.msme_img} target={"_blank"}>
                            <img
                              src={editableData?.msme_img}
                              className="mb-2"
                              style={{ width: 150, height: 150 }}
                              alt={"MSME"}
                            />
                          </Link>
                          <br />
                        </>
                      ) : null}
                      <span>
                        <b>MSME Number:</b> {editableData?.msme_no || "N/A"}
                      </span>
                    </th>
                  </tr>
                </tbody>
              ) : editableData?.companyTypeName === "Any other entity" ? (
                <tbody>
                  <tr>
                    <th>
                      {editableData?.gst_img ? (
                        <>
                          <span>
                            <b>GST Image</b>
                          </span>{" "}
                          <br />
                          <Link to={editableData?.gst_img} target={"_blank"}>
                            <img
                              src={editableData?.gst_img}
                              className="mb-2"
                              style={{ width: 150, height: 150 }}
                              alt={"GST"}
                            />
                          </Link>
                          <br />
                        </>
                      ) : null}
                      <span>
                        <b>GST Number:</b> {editableData?.gst_no || "N/A"}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      {editableData?.pancard_img ? (
                        <>
                          <span>
                            <b>PAN Image</b>
                          </span>{" "}
                          <br />
                          <Link
                            to={editableData?.pancard_img}
                            target={"_blank"}
                          >
                            <img
                              src={editableData?.pancard_img}
                              className="mb-2"
                              style={{ width: 150, height: 150 }}
                              alt={"PAN Card"}
                            />
                          </Link>
                          <br />
                        </>
                      ) : null}
                      <span>
                        <b>PAN Number:</b> {editableData?.pancard_no || "N/A"}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      {editableData?.coi_img ? (
                        <>
                          <span>
                            <b>COI Image</b>
                          </span>{" "}
                          <br />
                          <Link to={editableData?.coi_img} target={"_blank"}>
                            <img
                              src={editableData?.coi_img}
                              className="mb-2"
                              style={{ width: 150, height: 150 }}
                              alt={"COI"}
                            />
                          </Link>
                          <br />
                        </>
                      ) : null}
                      <span>
                        <b>COI Number:</b> {editableData?.coi_no || "N/A"}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      {editableData?.msme_img ? (
                        <>
                          <span>
                            <b>MSME Image</b>
                          </span>{" "}
                          <br />
                          <Link to={editableData?.msme_img} target={"_blank"}>
                            <img
                              src={editableData?.msme_img}
                              className="mb-2"
                              style={{ width: 150, height: 150 }}
                              alt={"MSME"}
                            />
                          </Link>
                          <br />
                        </>
                      ) : null}
                      <span>
                        <b>MSME Number:</b> {editableData?.msme_no || "N/A"}
                      </span>
                    </th>
                  </tr>
                </tbody>
              ) : editableData?.companyTypeName === "Trust" ? (
                <tbody>
                  <tr>
                    <th>
                      {editableData?.gst_img ? (
                        <>
                          <span>
                            <b>GST Image</b>
                          </span>{" "}
                          <br />
                          <Link to={editableData?.gst_img} target={"_blank"}>
                            <img
                              src={editableData?.gst_img}
                              className="mb-2"
                              style={{ width: 150, height: 150 }}
                              alt={"GST"}
                            />
                          </Link>
                          <br />
                        </>
                      ) : null}
                      <span>
                        <b>GST Number:</b> {editableData?.gst_no || "N/A"}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      {editableData?.pancard_img ? (
                        <>
                          <span>
                            <b>PAN Image</b>
                          </span>{" "}
                          <br />
                          <Link
                            to={editableData?.pancard_img}
                            target={"_blank"}
                          >
                            <img
                              src={editableData?.pancard_img}
                              className="mb-2"
                              style={{ width: 150, height: 150 }}
                              alt={"PAN Card"}
                            />
                          </Link>
                          <br />
                        </>
                      ) : null}
                      <span>
                        <b>PAN Number:</b> {editableData?.pancard_no || "N/A"}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      {editableData?.trust_img ? (
                        <>
                          <span>
                            <b>Trust Deed Image</b>
                          </span>{" "}
                          <br />
                          <Link to={editableData?.trust_img} target={"_blank"}>
                            <img
                              src={editableData?.trust_img}
                              className="mb-2"
                              style={{ width: 150, height: 150 }}
                              alt={"TRUST"}
                            />
                          </Link>
                          <br />
                        </>
                      ) : null}
                      <span>
                        <b>Trust Deed Number:</b>{" "}
                        {editableData?.trust_no || "N/A"}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      {editableData?.msme_img ? (
                        <>
                          <span>
                            <b>MSME Image</b>
                          </span>{" "}
                          <br />
                          <Link to={editableData?.msme_img} target={"_blank"}>
                            <img
                              src={editableData?.msme_img}
                              className="mb-2"
                              style={{ width: 150, height: 150 }}
                              alt={"MSME"}
                            />
                          </Link>
                          <br />
                        </>
                      ) : null}
                      <span>
                        <b>MSME Number:</b> {editableData?.msme_no || "N/A"}
                      </span>
                    </th>
                  </tr>
                </tbody>
              ) : editableData?.companyTypeName === "Society" ? (
                <tbody>
                  <tr>
                    <th>
                      {editableData?.gst_img ? (
                        <>
                          <span>
                            <b>GST Image</b>
                          </span>{" "}
                          <br />
                          <Link to={editableData?.gst_img} target={"_blank"}>
                            <img
                              src={editableData?.gst_img}
                              className="mb-2"
                              style={{ width: 150, height: 150 }}
                              alt={"GST"}
                            />
                          </Link>
                          <br />
                        </>
                      ) : null}
                      <span>
                        <b>GST Number:</b> {editableData?.gst_no || "N/A"}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      {editableData?.pancard_img ? (
                        <>
                          <span>
                            <b>PAN Image</b>
                          </span>{" "}
                          <br />
                          <Link
                            to={editableData?.pancard_img}
                            target={"_blank"}
                          >
                            <img
                              src={editableData?.pancard_img}
                              className="mb-2"
                              style={{ width: 150, height: 150 }}
                              alt={"PAN Card"}
                            />
                          </Link>
                          <br />
                        </>
                      ) : null}
                      <span>
                        <b>PAN Number:</b> {editableData?.pancard_no || "N/A"}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      {editableData?.rc_img ? (
                        <>
                          <span>
                            <b>RC Image</b>
                          </span>{" "}
                          <br />
                          <Link to={editableData?.rc_img} target={"_blank"}>
                            <img
                              src={editableData?.rc_img}
                              className="mb-2"
                              style={{ width: 150, height: 150 }}
                              alt={"RC"}
                            />
                          </Link>
                          <br />
                        </>
                      ) : null}
                      <span>
                        <b>RC Number:</b> {editableData?.rc_no || "N/A"}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      {editableData?.moa_img ? (
                        <>
                          <span>
                            <b>MOA Image</b>
                          </span>{" "}
                          <br />
                          <Link to={editableData?.moa_img} target={"_blank"}>
                            <img
                              src={editableData?.moa_img}
                              className="mb-2"
                              style={{ width: 150, height: 150 }}
                              alt={"MOA"}
                            />
                          </Link>
                          <br />
                        </>
                      ) : null}
                      <span>
                        <b>MOA Number:</b> {editableData?.moa_no || "N/A"}
                      </span>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      {editableData?.msme_img ? (
                        <>
                          <span>
                            <b>MSME Image</b>
                          </span>{" "}
                          <br />
                          <Link to={editableData?.msme_img} target={"_blank"}>
                            <img
                              src={editableData?.msme_img}
                              className="mb-2"
                              style={{ width: 150, height: 150 }}
                              alt={"MSME"}
                            />
                          </Link>
                          <br />
                        </>
                      ) : null}
                      <span>
                        <b>MSME Number:</b> {editableData?.msme_no || "N/A"}
                      </span>
                    </th>
                  </tr>
                </tbody>
              ) : null}
            </table>
          </div>
        </div>
      </div>
      <EMCKYCApprovalModal
        modal={modal}
        setModal={setModal}
        editableData={editableData}
        getEMCList={getEMCList}
        handleClose={handleClose}
      />
    </div>
  );
}

export default EMCDetailsModal;
