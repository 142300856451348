import { Link } from "react-router-dom";
import { LOGO } from "../utils/Constants";

function Header({ logoutHandle }: any) {
  return (
    <nav className="navbar">
      <div className="container-fluid d-flex justify-content-between align-items-center">
        <div
          className="navbar-header d-flex align-items-center"
          style={{ borderRight: "1px solid #e7e7e7" }}
        >
          <Link
            to="#"
            className="navbar-toggle collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#navbar-collapse"
            aria-expanded="false"
            style={{ color: "black" }}
          />
          <Link to="#" className="bars" />
          <Link className="navbar-brand" to="/dashboard">
            <img
              className="logo-name manage_logo"
              src={LOGO}
              alt="Abhhyam Secured Life"
              title="Abhhyam Secured Life"
            />
          </Link>
        </div>

        {/* Centered Text */}
        <div className="text-center flex-grow-1">
          <h4 className="m-0 p-0 text-white">ADMIN PANEL</h4>
        </div>

        <ul className="nav navbar-nav navbar-right">
          <li className="dropdown user_profile">
            <div className="dropdown-toggle" data-bs-toggle="dropdown">
              <img
                src="http://admin.abhhyamsecure.com/assets/images/asl_logo.jpeg"
                alt="user"
                width={30}
              />
            </div>
            <ul className="dropdown-menu pullDown">
              <li className="body" style={{ float: "none" }}>
                <ul className="user_dw_menu">
                  <li>
                    <Link to="#" onClick={() => logoutHandle()}>
                      <i className="material-icons" />
                      Logout
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Header;
