import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import WebAction from "../stores/action/webAction";

type FormData = {
  title: string;
  image: string;
};

function AddDownload({
  setCatModal,
  catModal,
  type,
  editableData,
  getData,
}: any) {
  const dispatch = useDispatch<any>();
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, reset, setValue } = useForm<FormData>();
  const handleClose = () => setCatModal(false);

  useEffect(() => {
    if (type === "update" && editableData) {
      setValue("title", editableData.title || "");
      setValue("image", editableData.image || "");
    } else {
      setValue("title", "");
      setValue("image", "");
    }
  }, [type, editableData, catModal, setValue]);

  const formHandleSubmit = async (data: any) => {
    setLoading(true);
    try {
      const action =
        type === "add" ? WebAction.addDocument : WebAction.updateDocument;
      const payload: any =
        type === "add"
          ? { title: data.title, image: data.image[0] }
          : { id: editableData.id, title: data.title, image: data.image[0] };

      await dispatch(action(payload));
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      getData();
      reset();
      handleClose();
      setLoading(false);
    }
  };

  return (
    <div className={`modal ${catModal ? "d-block" : "d-none"}`} role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <form
            onSubmit={handleSubmit(formHandleSubmit)}
            encType="multipart/form-data"
          >
            <div className="modal-header p-0">
              <h5 className="modal-title m-0">
                {type === "add" ? "Add" : "Update"} Download
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => setCatModal(false)}
              />
            </div>
            <div className="modal-body p-0 py-3 mt-3">
              <div className="form-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  {...register("title")}
                  placeholder="Document Name"
                  required
                />
              </div>
              <div className="form-group mb-3">
                <input
                  type="file"
                  className="form-control"
                  {...register("image")}
                  accept={"application/pdf"}
                  required={
                    editableData.image === "" ||
                    editableData.image === null ||
                    editableData.image === undefined
                  }
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                disabled={loading}
                onClick={() => setCatModal(false)}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                {loading && <i className="fa fa-spinner fa-spin"></i>}&nbsp;
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddDownload;
