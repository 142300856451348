import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import StateWiseSalaryAction from "../stores/action/stateWiseSalaryAction";
import { RootState } from "../stores";
import WebAction from "../stores/action/webAction";
import toast from "react-hot-toast";

type FormData = {
  state: string;
  employmentType: string;
  amount: string;
};

function AddStateWiseSalary({
  setStateWiseSalaryModal,
  stateWiseSalaryModal,
  type,
  editableData,
  getData,
}: any) {
  const handleClose = () => setStateWiseSalaryModal(false);
  const dispatch = useDispatch<any>();
  const { getStateList } = useSelector((state: RootState) => state.web);
  const [loading, setLoading] = useState(false);

  const getStateData = useCallback(async () => {
    await dispatch(WebAction.getState());
  }, [dispatch]);

  useEffect(() => {
    getStateData();
  }, [getStateData]);

  const { register, handleSubmit, reset, setValue } = useForm<FormData>();

  useEffect(() => {
    if (type === "update" && editableData) {
      setValue("amount", editableData.amount);
    } else {
      setValue("amount", "");
      setValue("state", "");
      setValue("employmentType", "");
    }
  }, [type, editableData, stateWiseSalaryModal, setValue]);

  const formHandleSubmit = async (data: any) => {
    setLoading(true);
    try {
      if (type === "add") {
        await dispatch(
          StateWiseSalaryAction.addStateWiseSalary({
            amount: data.amount,
            state: data.state,
            employment_type: data.employmentType,
          }),
        );
      } else {
        await dispatch(
          StateWiseSalaryAction.updateStateWiseSalary({
            id: editableData.id,
            amount: data.amount,
          }),
        );
      }
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      getData();
      reset();
      handleClose();
      setLoading(false);
    }
  };

  return (
    <div
      className="modal"
      style={{ display: stateWiseSalaryModal ? "block" : "none" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <form
            onSubmit={handleSubmit(formHandleSubmit)}
            className="needs-validation"
            encType="multipart/form-data"
          >
            <div className="modal-header p-0">
              <h5 className="modal-title m-0">
                {type === "add" ? "Add" : "Update"} State Wise Salary
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={handleClose}
              />
            </div>
            <div className="modal-body p-0 py-3 mt-3">
              <div className={"row"}>
                {type === "add" ? (
                  <>
                    <div className={"col-lg-12"}>
                      <div className="form-group">
                        <select
                          {...register("state")}
                          className="form-control"
                          required
                        >
                          <option value="" selected disabled>
                            Select State *
                          </option>
                          {getStateList &&
                            getStateList?.map((stateData: any) => {
                              return (
                                <option key={stateData.id} value={stateData.id}>
                                  {stateData.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div className={"col-lg-12"}>
                        <div className="form-group">
                          <select
                            {...register("employmentType")}
                            className="form-control"
                            required
                          >
                            <option value="" selected disabled>
                              Select Skill Category *
                            </option>
                            <option value="SKILLED">SKILLED</option>
                            <option value="SEMISKILLED">SEMISKILLED</option>
                            <option value="UNSKILLED">UNSKILLED</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                <div className={"col-lg-12"}>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      {...register("amount")}
                      placeholder="Amount *"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger light"
                data-bs-dismiss="modal"
                disabled={loading}
                onClick={handleClose}
              >
                Close
              </button>
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary px-4"
              >
                {loading ? <i className={"fa fa-spinner fa-spin"}></i> : null}
                &nbsp; Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddStateWiseSalary;
