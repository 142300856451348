import WebLayout from "../../layout/WebLayout";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import EnquiryAction from "../../stores/action/enquiryAction";
import EmployerAction from "../../stores/action/employerAction";
import toast from "react-hot-toast";
import Pagination from "../../components/Pagination";
import TableLoader from "../../components/TableLoader";
import Breadcrumb from "../../components/Breadcrumb";

function EnquiryList() {
  const [search, setSearch] = useState<any>("");
  const [limit, setLimit] = useState<any>(10);
  const [page, setPage] = useState<any>(1);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch<any>();

  const {
    enquiryList,
    lastPage,
    totalData,
    from,
    to,
    totalEnquiry,
    todayEnquiry,
  } = useSelector((state: RootState) => state.enquiry);

  const maxPagesToShow = 5;
  const pagination = [];

  const startPage = Math.max(page - Math.floor(maxPagesToShow / 2), 1);
  const endPage = Math.min(startPage + maxPagesToShow - 1, lastPage);

  for (let i = startPage; i <= endPage; i++) {
    pagination.push(i);
  }

  const getEnquiryList = useCallback(async () => {
    setLoading(true);

    try {
      await dispatch(
        EnquiryAction.getEnquiry({
          limit: limit,
          search: search,
          page: page,
        }),
      );
    } finally {
      setLoading(false);
    }
  }, [dispatch, limit, search, page]);

  useEffect(() => {
    getEnquiryList();
  }, [getEnquiryList, search, limit, page]);

  const modalOpen = (id: number) => {
    setSelectedId(id);
    setConfirmModal(true);
  };
  const handleClose = () => setConfirmModal(false);

  const createEmployerAccount = async () => {
    try {
      if (selectedId) {
        await dispatch(EmployerAction.createEmployer({ id: selectedId }));
        getEnquiryList();
      }
      handleClose();
    } catch (error: any) {
      toast.error("Something went wrong while creating the employer account.");
    }
  };

  return (
    <WebLayout pageName={"Home"}>
      <section className="content">
        <div
          className="modal"
          style={{ display: confirmModal ? "block" : "none" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirm Registration</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleClose}
                />
              </div>
              <div className="modal-body">
                Are you sure you want to register this inquiry as an employer?
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={createEmployerAccount}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <Breadcrumb
                mainTitle={"Manage Enquiry"}
                subTitle={"EMC Enquiry List"}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <div
                  className="header"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h2>ALL EMC ENQUIRY LIST</h2>
                  <div>
                    <button
                      className="btn btn-primary btn-lg btn-block"
                      type="button"
                    >
                      <i className="fa fa-envelope"></i> Total Enquiry
                      <span className="badge font-bold">{totalEnquiry}</span>
                    </button>

                    <button
                      className="btn btn-danger btn-lg btn-block m-l-10"
                      type="button"
                    >
                      <i className="fa fa-envelope"></i> Today Enquiry
                      <span className="badge font-bold">{todayEnquiry}</span>
                    </button>
                  </div>
                </div>
                <div className="row g-3 px-4 mb-2 d-flex align-items-end">
                  <div className="col-md-3">
                    <label className="mb-2">Show Entries</label>
                    <select
                      onChange={(e) => setLimit(e.target.value)}
                      id="defaultSelect"
                      className="form-select"
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <div className="col-md-6"></div>
                  <div className="col-md-3">
                    <div className="input-group input-group-merge mb-1">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="body">
                  <div className="table-responsive">
                    <table
                      id="basicTable"
                      className="table table-hover table-checkable order-column contact_list"
                    >
                      <thead>
                        <tr>
                          <th className="center border-radius-per-0">#</th>
                          <th className="center border-radius-per-0"> Date</th>
                          <th className="center border-radius-per-0"> Name</th>
                          <th className="center border-radius-per-0">Mobile</th>
                          <th className="center border-radius-per-0"> Email</th>
                          <th className="center border-radius-per-0"> State</th>
                          <th className="center border-radius-per-0">
                            District
                          </th>
                          <th className="center border-radius-per-0">
                            Pin Code
                          </th>
                          <th className="center border-radius-per-0">Status</th>
                          <th className="center border-radius-per-0">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <TableLoader column={9} />
                        ) : enquiryList?.length > 0 ? (
                          enquiryList.map((dataList: any, index: number) => {
                            const formatDate = (dateString: string) => {
                              const date = new Date(dateString);
                              return date.toLocaleDateString("en-GB");
                            };
                            return (
                              <tr key={index}>
                                <td className="center">{from + index}</td>
                                <td className="center">
                                  {formatDate(dataList.date)}
                                </td>
                                <td className="center">
                                  {dataList.empName || "N/A"}
                                </td>
                                <td className="center">
                                  {dataList.mobile || "N/A"}
                                </td>
                                <td className="center">
                                  {dataList.email || "N/A"}
                                </td>
                                <td className="center">
                                  {dataList.sName || "N/A"}
                                </td>
                                <td className="center">
                                  {dataList.dName || "N/A"}
                                </td>
                                <td className="center">
                                  {dataList.pincode || "N/A"}
                                </td>
                                <td className="center">
                                  <label className={"btn btn-warning btn-sm"}>
                                    Enquiry Pending
                                  </label>
                                </td>
                                <td className="center">
                                  {dataList.status === 0 ? (
                                    <button
                                      onClick={() => modalOpen(dataList.id)}
                                      className="btn btn-success btn-sm"
                                    >
                                      Proceed to Registration
                                    </button>
                                  ) : (
                                    <button className="btn btn-danger btn-sm">
                                      Done
                                    </button>
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={9} className="text-center">
                              No matching records found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {enquiryList?.length > 0 ? (
                    <Pagination
                      from={from}
                      to={to}
                      totalData={totalData}
                      setPage={setPage}
                      page={page}
                      pagination={pagination}
                      lastPage={lastPage}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default EnquiryList;
