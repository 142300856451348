const EnquiryType = {
  SET_ENQUIRY_LIST: "SET_ENQUIRY_LIST",
  SET_CONTACT_LIST: "SET_CONTACT_LIST",
  UPDATE_CONTACT_FEEDBACK: "UPDATE_CONTACT_FEEDBACK",
};

export interface GetEnquiry {
  search: string;
  page: number;
  limit: number;
}

export interface GetContact {
  search: string;
  page: number;
  limit: number;
}

export interface UpdateContact {
  id: number;
  feedback: string;
}

export default EnquiryType;
