import { Link } from "react-router-dom";
import WebLayout from "../../layout/WebLayout";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { RootState } from "../../stores";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/Pagination";
import Breadcrumb from "../../components/Breadcrumb";
import PVAction from "../../stores/action/PVAction";

function PvHistoryList() {
  const dispatch = useDispatch<any>();

  const [search, setSearch] = useState<string>("");
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(1);

  const [date, setDate] = useState<string>(
    new Date().toISOString().slice(0, 7),
  );

  const currentMonth = new Date().toISOString().slice(0, 7);

  const { pvHistoryList, lastPage, totalData, from, to } = useSelector(
    (state: RootState) => state.pv,
  );

  const maxPagesToShow = 5;

  const pagination = useMemo(() => {
    const startPage = Math.max(page - Math.floor(maxPagesToShow / 2), 1);
    const endPage = Math.min(startPage + maxPagesToShow - 1, lastPage);
    return Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i,
    );
  }, [lastPage, page]);

  const fetchPvHistory = useCallback(async () => {
    await dispatch(
      PVAction.getPvHistory({
        search,
        limit,
        page,
        date,
      }),
    );
  }, [dispatch, search, limit, page, date]);

  useEffect(() => {
    fetchPvHistory();
  }, [fetchPvHistory]);

  return (
    <WebLayout pageName="PvHistoryList">
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <Breadcrumb mainTitle={"PV History"} subTitle={"PV History"} />
            </div>
          </div>
          <div className="card">
            <div className="d-flex justify-content-between p-3">
              {/* Show Entries Dropdown */}
              <div className="col-md-3">
                <label className="form-label">Show Entries</label>
                <select
                  value={limit}
                  onChange={(e) => setLimit(Number(e.target.value))}
                  id="defaultSelect"
                  className="form-select"
                  style={{ height: 40 }}
                >
                  {[10, 25, 50, 100].map((num) => (
                    <option key={num} value={num}>
                      {num}
                    </option>
                  ))}
                </select>
              </div>

              {/* Month Picker */}
              <div className="col-md-3">
                <label htmlFor="monthPicker" className="form-label">
                  Select Month & Year:
                </label>
                <input
                  id="monthPicker"
                  type="month"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  max={currentMonth}
                  className="form-control"
                  style={{ height: 40 }}
                />
              </div>

              {/* Search Input */}
              <div className="col-md-3">
                <label className="form-label">Search</label>
                <input
                  type="text"
                  className="form-control"
                  style={{ height: 40 }}
                  placeholder="Search..."
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                />
              </div>
            </div>

            <div className="body">
              <div className="table-responsive">
                <table className="table table-hover table-checkable">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>ASL ID</th>
                      <th>Company Info</th>
                      <th>Generate Date</th>
                      <th>Releasing Date</th>
                      <th>PP</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pvHistoryList.length > 0 ? (
                      pvHistoryList.map((dataList: any, index: number) => (
                        <tr key={index}>
                          <td className="center">{index + 1}</td>
                          <td className="center">
                            <label className={"label label-success"}>
                              {dataList?.username}
                            </label>
                          </td>
                          <td className="center">
                            <b>EMC Id : </b>
                            {dataList?.emp_no} <br />
                            <b>Name : </b>
                            {dataList?.cName} <br />
                            <b>Email : </b>
                            {dataList?.cEmail}
                          </td>
                          <td className="center">{dataList?.date}</td>
                          <td className="center">{dataList?.pay_date}</td>
                          <td>{dataList?.total_pv}</td>
                          <td>
                            {dataList?.status === "PENDING" ? (
                              <Link
                                to="#"
                                className="label label-warning rounded-0"
                              >
                                {dataList?.status}
                              </Link>
                            ) : dataList?.status === "RELEASED" ? (
                              <Link
                                to="#"
                                className="label label-success rounded-0"
                              >
                                {dataList?.status}
                              </Link>
                            ) : null}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={10} className="text-center">
                          No matching records found!
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {pvHistoryList?.length > 0 && (
                <Pagination
                  from={from}
                  to={to}
                  totalData={totalData}
                  setPage={setPage}
                  page={page}
                  pagination={pagination}
                  lastPage={lastPage}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default PvHistoryList;
