import EnquiryType from "../type/enquiryType";

export interface EnquiryAction {
  type: string;
  payload?: any;
}

const INITIAL_STATE = {
  enquiryList: [],
  contactList: [],
  currentPage: 0,
  lastPage: 0,
  totalData: 0,
  from: 0,
  to: 0,
  totalEnquiry: 0,
  todayEnquiry: 0,
};

const EnquiryReducers = (state = INITIAL_STATE, action: EnquiryAction) => {
  switch (action.type) {
    case EnquiryType.SET_ENQUIRY_LIST:
      return {
        ...state,
        enquiryList: action.payload.data,
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        totalData: action.payload.totalData,
        from: action.payload.from,
        to: action.payload.to,
        totalEnquiry: action.payload.totalEnquiry,
        todayEnquiry: action.payload.todayEnquiry,
      };

    case EnquiryType.SET_CONTACT_LIST:
      return {
        ...state,
        contactList: action.payload.data,
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        totalData: action.payload.totalData,
        from: action.payload.from,
        to: action.payload.to,
      };

    case EnquiryType.UPDATE_CONTACT_FEEDBACK:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default EnquiryReducers;
