// export const BASE_URL = "http://localhost/asl_api/api/v1/admin/";
export const BASE_URL = "https://abhhyamsecure.com/api/api/v1/admin/";
export const ADMIN_USER_ID = "@ASL_ADMIN:ADMIN_USER_ID";
export const ADMIN_TOKEN = "@ASL_TOKEN: ADMIN_TOKEN";

export const ERM_URL = "https://erm.abhhyamsecure.com/";
export const EMC_URL = "https://emc.abhhyamsecure.com/";
export const ASL_URL = "https://asl.abhhyamsecure.com/";

export const LOGO = "/assets/images/asl_logo.png";
export const LOGIN_IMG =
  "/assets/images/hand-drawn-man-working-from-home-b.png";
