import { Link } from "react-router-dom";
import React from "react";

const Breadcrumb = ({ mainTitle, subTitle, childTitle }: any) => (
  <div className="col-12 mb-3">
    <h3 className={"m-0"} style={{ fontSize: 20, color: "#444444" }}>
      {mainTitle}
    </h3>
    <ul className="breadcrumb breadcrumb-style mb-0 p-10">
      <li className="breadcrumb-item active">
        <Link to="/dashboard">
          <i className="fas fa-home"></i> Home
        </Link>
      </li>
      <li className="breadcrumb-item active">{subTitle}</li>
      {childTitle ? (
        <li className="breadcrumb-item active">{childTitle}</li>
      ) : null}
    </ul>
  </div>
);

export default Breadcrumb;
