import { Link, useParams } from "react-router-dom";
import WebLayout from "../../layout/WebLayout";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AttendanceAction from "../../stores/action/attendanceAction";
import { RootState } from "../../stores";
import Pagination from "../../components/Pagination";

function EmployeeWiseAttendanceList() {
  const [search, setSearch] = useState<any>("");
  const [limit, setLimit] = useState<any>(10);
  const [page, setPage] = useState<number>(1);
  const { id } = useParams<any>();
  const dispatch = useDispatch<any>();

  const {
    employeeWiseAttendanceList,
    currentPage,
    lastPage,
    totalData,
    from,
    to,
    employee,
  } = useSelector((state: RootState) => state.attendance);

  const maxPagesToShow = 5;
  const pagination = [];

  const startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
  const endPage = Math.min(startPage + maxPagesToShow - 1, lastPage);

  for (let i = startPage; i <= endPage; i++) {
    pagination.push(i);
  }

  const getEmployeeWiseAttendance = useCallback(async () => {
    await dispatch(
      AttendanceAction.getEmployeeWiseAttendanceList({
        id: Number(id),
        search: search,
        limit: limit,
        page: page,
      }),
    );
  }, [dispatch, id, search, limit, page]);

  useEffect(() => {
    getEmployeeWiseAttendance();
  }, [getEmployeeWiseAttendance, search, limit, page]);

  return (
    <WebLayout pageName={"AttendanceList"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
                <ul className="breadcrumb breadcrumb-style ">
                  <Link
                    to="/attendance-list"
                    className="btn btn-success btn-sm"
                  >
                    <i className="fa fa-arrow-circle-left"></i>&nbsp; Back
                  </Link>
                  &nbsp;
                  <li className="breadcrumb-item">
                    <h4 className="page-title">EMA Wise Attendance List</h4>
                  </li>
                  <li className="breadcrumb-item bcrumb-1">
                    <Link to="/dashboard">
                      <i className="fas fa-home"></i> Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    EMA Wise Attendance List
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="header">
              <div className="chat p-2">
                <div className="chat-header clearfix">
                  <img src={employee?.profile_img} alt="avatar" />
                  <div className="row">
                    <div className="col-lg-3 col-sm-6">
                      <div className="chat-about">
                        <div className="chat-with">{employee?.name}</div>
                        <div className="chat-num-messages">
                          {employee?.designation}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                      <div className="chat-about">
                        <div className="chat-with">Employee ID</div>
                        <div className="chat-num-messages">
                          {employee?.emp_no}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                      <div className="chat-about">
                        <div className="chat-with">Total Hours</div>
                        <div className="chat-num-messages">
                          {employee?.total_hours}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                      <div className="chat-about">
                        <div className="chat-with">Total Leaves</div>
                        <div className="chat-num-messages">
                          {employee?.totalLeave}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="body pt-0">
              <div
                className="row  d-flex
              justify-content-between
              align-items-end"
              >
                <div className="col-md-3">
                  <label className="mb-2">Show Entries</label>
                  <select
                    onChange={(e) => setLimit(e.target.value)}
                    id="defaultSelect"
                    className="form-select"
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-3">
                  <div className="input-group input-group-merge mb-1">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table
                  id="basicTable"
                  className="table table-hover table-checkable order-column contact_list"
                >
                  <thead>
                    <tr>
                      <th className="center">#</th>
                      <th className="center">Date</th>
                      <th className="center">In Time</th>
                      <th className="center">Out Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {employeeWiseAttendanceList?.length > 0 ? (
                      employeeWiseAttendanceList.map(
                        (dataList: any, index: number) => {
                          return (
                            <>
                              <tr key={index}>
                                <td>{from + index}</td>
                                <td>{dataList.date}</td>
                                <td>{dataList.in_time}</td>
                                <td>{dataList.out_time}</td>
                              </tr>
                            </>
                          );
                        },
                      )
                    ) : (
                      <tr>
                        <td colSpan={10}>No matching records found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {employeeWiseAttendanceList?.length > 0 ? (
                <Pagination
                  from={from}
                  to={to}
                  totalData={totalData}
                  setPage={setPage}
                  page={page}
                  pagination={pagination}
                  lastPage={lastPage}
                />
              ) : null}
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default EmployeeWiseAttendanceList;
