import { useDispatch, useSelector } from "react-redux";
import WebLayout from "../../layout/WebLayout";
import { RootState } from "../../stores";
import { useCallback, useEffect, useState } from "react";
import WebAction from "../../stores/action/webAction";
import { Link, useParams } from "react-router-dom";
import AddCategoryModal from "../../components/AddCategoryModal";
import Pagination from "../../components/Pagination";
import Breadcrumb from "../../components/Breadcrumb";
import FilterSorting from "../../components/FilterSorting";
import TableLoader from "../../components/TableLoader";

function Category() {
  const dispatch = useDispatch<any>();
  const { offerType } = useParams<any>();

  const { getCategoryList, lastPage, totalData, from, to } = useSelector(
    (state: RootState) => state.web,
  );

  const [catModal, setCatModal] = useState<boolean>(false);
  const [modelType, setModelType] = useState<any>("add");
  const [editableData, setEditableData] = useState<string>("add");
  const [search, setSearch] = useState<any>();
  const [limit, setLimit] = useState<any>(10);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);

  const modalOpen = (type: string) => {
    setCatModal(true);
    setModelType(type);
  };

  const maxPagesToShow = 5;
  const pagination = [];

  const startPage = Math.max(page - Math.floor(maxPagesToShow / 2), 1);
  const endPage = Math.min(startPage + maxPagesToShow - 1, lastPage);

  for (let i = startPage; i <= endPage; i++) {
    pagination.push(i);
  }

  const getData = useCallback(async () => {
    setLoading(true);
    try {
      await dispatch(
        WebAction.getCategory({
          limit: limit,
          search: search,
          page: page,
          type: offerType,
        }),
      );
    } finally {
      setLoading(false);
    }
  }, [dispatch, limit, search, page, offerType]);

  useEffect(() => {
    getData();
  }, [getData, search, limit, page]);

  const deleteData = async (id: number) => {
    await dispatch(WebAction.deleteCategory({ id: id }));
    getData();
  };
  return (
    <WebLayout pageName={"Category"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <Breadcrumb
                mainTitle={"Setting"}
                subTitle={
                  offerType === "PLAN"
                    ? "Offer Plan Category List"
                    : offerType === "POLICY"
                      ? "Policy Category List"
                      : offerType === "FAQ"
                        ? "FAQ Question List"
                        : ""
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <div className="header">
                  <h2>
                    {offerType === "PLAN"
                      ? "OFFER PLAN"
                      : offerType === "POLICY"
                        ? "POLICY"
                        : offerType === "FAQ"
                          ? "FAQ"
                          : ""}
                    &nbsp;CATEGORY LIST
                  </h2>
                  <div className="header-dropdown m-r--5">
                    {catModal && (
                      <AddCategoryModal
                        setCatModal={setCatModal}
                        catModal={catModal}
                        type={modelType}
                        editableData={editableData}
                        getData={getData}
                        offerType={offerType}
                      />
                    )}
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={() => modalOpen("add")}
                    >
                      {offerType === "PLAN"
                        ? "Add Offer Plan Category"
                        : offerType === "POLICY"
                          ? "Add Policy Category"
                          : offerType === "FAQ"
                            ? "Add FAQ Question"
                            : ""}
                    </button>
                  </div>
                </div>
                <FilterSorting setLimit={setLimit} setSearch={setSearch} />
                <div className="body">
                  <div className="table-responsive">
                    <table
                      id="basicTable"
                      className="table table-hover table-checkable order-column contact_list"
                    >
                      <thead>
                        <tr>
                          <th className="center">#</th>
                          <th className="center">Name</th>
                          <th className="center">Content</th>
                          <th className="center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <TableLoader column={4} />
                        ) : getCategoryList?.length > 0 ? (
                          getCategoryList.map(
                            (dataList: any, index: number) => {
                              return (
                                <tr key={index}>
                                  <td>{from + index}</td>
                                  <td>{dataList.name}</td>
                                  <td>
                                    {offerType === "PLAN" ? (
                                      <Link
                                        to={`/plan-offer-plan/${dataList.id}`}
                                      >
                                        <button className={"btn btn-warning"}>
                                          Add Content
                                        </button>
                                      </Link>
                                    ) : offerType === "POLICY" ? (
                                      <Link
                                        to={`/policy-offer-plan/${dataList.id}`}
                                      >
                                        <button className={"btn btn-warning"}>
                                          Add Content
                                        </button>
                                      </Link>
                                    ) : offerType === "FAQ" ? (
                                      <Link
                                        to={`/faq-offer-plan/${dataList.id}`}
                                      >
                                        <button className={"btn btn-warning"}>
                                          Add Content
                                        </button>
                                      </Link>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>
                                    <button
                                      onClick={() => {
                                        modalOpen("update");
                                        setEditableData(dataList);
                                      }}
                                      className={"btn btn-success btn-sm"}
                                    >
                                      Edit
                                    </button>
                                    <button
                                      onClick={() => deleteData(dataList.id)}
                                      className="btn btn-danger btn-sm m-l-10"
                                    >
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              );
                            },
                          )
                        ) : (
                          <tr>
                            <td colSpan={4} className="text-center">
                              No matching records found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {getCategoryList?.length > 0 ? (
                    <Pagination
                      from={from}
                      to={to}
                      totalData={totalData}
                      setPage={setPage}
                      page={page}
                      pagination={pagination}
                      lastPage={lastPage}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default Category;
