import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import EnquiryAction from "../stores/action/enquiryAction";

type FormData = {
  name: string;
};

function ContactFeedbackModal({
  setFeedbackModal,
  feedbackModal,
  editableData,
  getData,
}: any) {
  const handleClose = () => setFeedbackModal(false);
  const dispatch = useDispatch<any>();
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, reset, setValue } = useForm<FormData>();

  useEffect(() => {
    setValue("name", editableData.feedback);
  }, [editableData, feedbackModal, setValue]);

  const formHandleSubmit = async (data: any) => {
    setLoading(true);
    try {
      const result: any = await dispatch(
        EnquiryAction.updateContactFeedback({
          id: editableData.id,
          feedback: data.name,
        }),
      );

      if (result?.status === false) {
        toast.error(result.msg);
        setLoading(false);
      } else {
        setLoading(false);
        reset();
        handleClose();
        getData();
        toast.success(result.msg);
      }
    } catch (error: any) {
      handleClose();
      setLoading(false);
    }
  };
  return (
    <div
      className="modal"
      style={{ display: feedbackModal ? "block" : "none" }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <form
            onSubmit={handleSubmit(formHandleSubmit)}
            className="needs-validation"
            encType="multipart/form-data"
          >
            <div className="modal-header p-0">
              <h5 className="modal-title m-0">Feedback</h5>
              <button
                type="button"
                className="btn-close"
                onClick={handleClose}
              />
            </div>
            <div className="modal-body p-0 py-3 mt-3">
              <div className="form-group">
                <input
                  className="form-control"
                  {...register("name")}
                  placeholder="Enter Comments"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger light"
                data-bs-dismiss="modal"
                disabled={loading}
                onClick={handleClose}
              >
                Close
              </button>
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary px-4"
              >
                {loading ? <i className={"fa fa-spinner fa-spin"}></i> : null}
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactFeedbackModal;
