import WebLayout from "../../layout/WebLayout";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import EmployerAction from "../../stores/action/employerAction";
import Pagination from "../../components/Pagination";
import Breadcrumb from "../../components/Breadcrumb";
import { Link } from "react-router-dom";
import { EMC_URL } from "../../utils/Constants";
import EMCDetailsModal from "../../components/employer/EMCDetailsModal";
import FilterSorting from "../../components/FilterSorting";
import TableLoader from "../../components/TableLoader";

function EmployerList() {
  const dispatch = useDispatch<any>();
  const [search, setSearch] = useState<any>("");
  const [limit, setLimit] = useState<any>(10);
  const [page, set_page] = useState<any>(1);
  const [type, setType] = useState<any>();
  const [editableData, setEditableData] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const {
    employerList,
    lastPage,
    totalData,
    from,
    to,
    totalPendingKYC,
    totalSendForApprovalKYC,
    totalApprovedKYC,
    totalRejectedKYC,
  } = useSelector((state: RootState) => state.employer);

  const maxPagesToShow = 5;
  const pagination = [];

  const startPage = Math.max(page - Math.floor(maxPagesToShow / 2), 1);
  const endPage = Math.min(startPage + maxPagesToShow - 1, lastPage);

  for (let i = startPage; i <= endPage; i++) {
    pagination.push(i);
  }

  const getEMCList = useCallback(async () => {
    setLoading(true);
    try {
      await dispatch(
        EmployerAction.getEmployer({
          limit: limit,
          search: search,
          page: page,
          type: type,
        }),
      );
    } finally {
      setLoading(false);
    }
  }, [dispatch, limit, search, page, type]);

  useEffect(() => {
    getEMCList();
  }, [getEMCList, search, limit, page, type]);

  return (
    <WebLayout pageName={"EmployerList"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <Breadcrumb mainTitle={"EMC"} subTitle={"EMC List"} />
            </div>
          </div>
          <EMCDetailsModal
            editableData={editableData}
            getEMCList={getEMCList}
          />
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <div
                  className="header"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h2>ALL EMC LIST</h2>
                  <div>
                    <button
                      className="btn btn-warning btn-sm btn-block"
                      type="button"
                      onClick={() => setType("NOT VERIFIED")}
                    >
                      <i className="fa fa-users"></i> Pending
                      <span className="badge font-bold">{totalPendingKYC}</span>
                    </button>

                    <button
                      className="btn btn-info btn-sm btn-block m-l-10"
                      type="button"
                      onClick={() => setType("SEND FOR APPROVAL")}
                    >
                      <i className="fa fa-users"></i> Received For Approval
                      <span className="badge font-bold text-info bg-white">
                        {totalSendForApprovalKYC}
                      </span>
                    </button>

                    <button
                      className="btn btn-success btn-sm btn-block m-l-10"
                      type="button"
                      onClick={() => setType("VERIFIED")}
                    >
                      <i className="fa fa-users"></i> Approved
                      <span className="badge font-bold">
                        {totalApprovedKYC}
                      </span>
                    </button>

                    <button
                      className="btn btn-danger btn-sm btn-block m-l-10"
                      type="button"
                      onClick={() => setType("REJECTED")}
                    >
                      <i className="fa fa-users"></i> Rejected
                      <span className="badge font-bold">
                        {totalRejectedKYC}
                      </span>
                    </button>

                    <button
                      className="btn btn-primary btn-sm btn-block m-l-10"
                      type="button"
                      onClick={() => setType("")}
                    >
                      <i className="fa fa-users"></i> ALL
                      <span className="badge font-bold">
                        {employerList?.length}
                      </span>
                    </button>
                  </div>
                </div>
                <FilterSorting setLimit={setLimit} setSearch={setSearch} />
                <div className="body">
                  <div className="table-responsive">
                    <table
                      id="basicTable"
                      className="table table-hover table-checkable order-column contact_list"
                    >
                      <thead>
                        <tr>
                          <th className="center">#</th>
                          <th className="center">EMC Id</th>
                          <th className="center">ASL ERM Rep. No.</th>
                          <th className="center">Company</th>
                          <th className="center">Email</th>
                          <th className="center">Contact Details</th>
                          <th className="center">KYC Status</th>
                          <th className="center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <TableLoader column={8} />
                        ) : employerList?.length > 0 ? (
                          employerList.map((dataList: any, index: number) => {
                            return (
                              <tr key={index}>
                                <td className={"center"}>{from + index}</td>
                                <td className={"center"}>
                                  {dataList.emp_no || "N/A"}
                                </td>
                                <td className={"center"}>
                                  {dataList.guide_id || "N/A"}
                                </td>
                                <td className={"text-left"} width={"20%"}>
                                  {dataList.company_image ? (
                                    <>
                                      <Link
                                        to={dataList.company_image}
                                        target={"_blank"}
                                      >
                                        <img
                                          src={dataList.company_image}
                                          alt="Company Logo"
                                          width={"50%"}
                                          height={100}
                                          style={{ objectFit: "contain" }}
                                        />
                                      </Link>
                                      <br />
                                    </>
                                  ) : null}
                                  <strong>Entity/Company Name</strong> :{" "}
                                  {dataList.company_name || "N/A"}
                                  <br />
                                  <strong>Company Type</strong> :{" "}
                                  {dataList.companyTypeName || "N/A"}
                                  <br />
                                  <strong>No. Of Employee</strong> :{" "}
                                  {dataList.no_of_employee || "N/A"}
                                </td>
                                <td className={"center"}>
                                  <Link
                                    to={`${EMC_URL}panel-login/${window.btoa(dataList.id)}`}
                                    className="btn btn-primary btn-sm"
                                    target={"_blank"}
                                  >
                                    {dataList.email}&nbsp;
                                    <i className={"fa fa-link"}></i>
                                  </Link>
                                </td>
                                <td className={"text-left"}>
                                  <strong>Manager Name</strong> :{" "}
                                  {dataList.name || "N/A"}
                                  <br />
                                  <strong>Mobile</strong> :{" "}
                                  {dataList.mobile || "N/A"}
                                  <br />
                                  <strong>Address</strong> :{" "}
                                  {dataList.address || "N/A"}
                                  <br />
                                  <strong>State</strong> :{" "}
                                  {dataList.sName || "N/A"}
                                  <br />
                                  <strong>District</strong> :{" "}
                                  {dataList.dName || "N/A"}
                                  <br />
                                  <strong>Pincode</strong> :{" "}
                                  {dataList.pincode || "N/A"}
                                </td>
                                <td className={"center"}>
                                  <button
                                    className={`btn btn-${dataList.kyc_status === "VERIFIED" ? "success" : dataList.kyc_status === "SEND FOR APPROVAL" ? "info" : dataList.kyc_status === "NOT VERIFIED" ? "warning" : "danger"} btn-sm cursor-not-allowed`}
                                  >
                                    {dataList.kyc_status === "SEND FOR APPROVAL"
                                      ? "RECEIVED FOR APPROVAL"
                                      : dataList.kyc_status === "NOT VERIFIED"
                                        ? "PENDING"
                                        : dataList.kyc_status === "VERIFIED"
                                          ? "Approved"
                                          : dataList.kyc_status}
                                  </button>
                                </td>
                                <td className={"center"}>
                                  <button
                                    className="btn btn-dark btn-sm"
                                    type="button"
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasRight"
                                    onClick={() => {
                                      setEditableData(dataList);
                                    }}
                                  >
                                    VIEW
                                  </button>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={8} className="text-center">
                              No matching records found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {employerList?.length > 0 ? (
                    <Pagination
                      from={from}
                      to={to}
                      totalData={totalData}
                      setPage={set_page}
                      page={page}
                      pagination={pagination}
                      lastPage={lastPage}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default EmployerList;
