import WebLayout from "../../layout/WebLayout";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import EnquiryAction from "../../stores/action/enquiryAction";
import Pagination from "../../components/Pagination";
import Breadcrumb from "../../components/Breadcrumb";
import ContactFeedbackModal from "../../components/ContactFeedbackModal";
import TableLoader from "../../components/TableLoader";
import FilterSorting from "../../components/FilterSorting";

function ContactList() {
  const dispatch = useDispatch<any>();

  const [search, setSearch] = useState<any>("");
  const [limit, setLimit] = useState<any>(10);
  const [page, set_page] = useState<any>(1);

  const [feedbackModal, setFeedbackModal] = useState<boolean>(false);
  const [editableData, setEditableData] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  const { contactList, lastPage, totalData, from, to } = useSelector(
    (state: RootState) => state.enquiry,
  );

  const maxPagesToShow = 5;
  const pagination = [];

  const startPage = Math.max(page - Math.floor(maxPagesToShow / 2), 1);
  const endPage = Math.min(startPage + maxPagesToShow - 1, lastPage);

  for (let i = startPage; i <= endPage; i++) {
    pagination.push(i);
  }

  const getContactList = useCallback(async () => {
    setLoading(true);
    try {
      await dispatch(
        EnquiryAction.getContact({
          limit: limit,
          search: search,
          page: page,
        }),
      );
    } finally {
      setLoading(false);
    }
  }, [dispatch, limit, search, page]);

  useEffect(() => {
    getContactList();
  }, [getContactList, search, limit, page]);

  return (
    <WebLayout pageName={"Home"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <Breadcrumb
                mainTitle={"Contact List"}
                subTitle={"Contact List"}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <div className="header">
                  <h2>ALL CONTACT LIST</h2>
                </div>
                <FilterSorting setLimit={setLimit} setSearch={setSearch} />
                <div className="body">
                  <div className="table-responsive">
                    <table
                      id="basicTable"
                      className="table table-hover table-checkable order-column contact_list"
                    >
                      <thead>
                        <tr>
                          <th className="center">#</th>
                          <th className="center">Name</th>
                          <th className="center">Mobile</th>
                          <th className="center">Email</th>
                          <th className="center">User Message</th>
                          <th className="center">Type</th>
                          <th className="center">Status</th>
                          <th className="center">Feedback</th>
                          <th className="center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <TableLoader column={9} />
                        ) : contactList?.length > 0 ? (
                          contactList.map((dataList: any, index: number) => {
                            return (
                              <tr key={index}>
                                <td className={"center"}>{from + index}</td>
                                <td className={"center"}>
                                  {dataList.name || "N/A"}
                                </td>
                                <td className={"center"}>
                                  {dataList.phone || "N/A"}
                                </td>
                                <td className={"center"}>
                                  {dataList.email || "N/A"}
                                </td>
                                <td className={"center"}>
                                  {dataList.message || "N/A"}
                                </td>
                                <td>
                                  <button
                                    className={`btn ${dataList.type === "REQUEST" ? "btn-primary" : "btn-success"} btn-sm`}
                                  >
                                    {dataList.type}
                                  </button>
                                </td>
                                <td>
                                  <button
                                    className={`btn ${dataList.status === "PENDING" ? "btn-warning" : "btn-danger"} btn-sm`}
                                  >
                                    {dataList.status}
                                  </button>
                                </td>
                                <td>{dataList.feedback || "N/A"}</td>
                                <td>
                                  <button
                                    className={"btn btn-success"}
                                    onClick={() => {
                                      setFeedbackModal(true);
                                      setEditableData(dataList);
                                    }}
                                  >
                                    Write Feedback
                                  </button>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={9} className="text-center">
                              No matching records found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {contactList?.length > 0 ? (
                    <Pagination
                      from={from}
                      to={to}
                      totalData={totalData}
                      setPage={set_page}
                      page={page}
                      pagination={pagination}
                      lastPage={lastPage}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <ContactFeedbackModal
            feedbackModal={feedbackModal}
            setFeedbackModal={setFeedbackModal}
            getData={getContactList}
            editableData={editableData}
          />
        </div>
      </section>
    </WebLayout>
  );
}

export default ContactList;
