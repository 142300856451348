import { useDispatch, useSelector } from "react-redux";
import WebLayout from "../../layout/WebLayout";
import { RootState } from "../../stores";
import { useCallback, useEffect, useState } from "react";
import WebAction from "../../stores/action/webAction";
import Pagination from "../../components/Pagination";
import Breadcrumb from "../../components/Breadcrumb";
import TableLoader from "../../components/TableLoader";
import FilterSorting from "../../components/FilterSorting";

function CompanyType() {
  const dispatch = useDispatch<any>();

  const { getCompanyTypeList, lastPage, totalData, from, to } = useSelector(
    (state: RootState) => state.web,
  );
  const [search, setSearch] = useState<any>();
  const [limit, setLimit] = useState<any>(10);
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);

  const maxPagesToShow = 5;
  const pagination = [];

  const startPage = Math.max(page - Math.floor(maxPagesToShow / 2), 1);
  const endPage = Math.min(startPage + maxPagesToShow - 1, lastPage);

  for (let i = startPage; i <= endPage; i++) {
    pagination.push(i);
  }

  const getData = useCallback(async () => {
    setLoading(true);
    try {
      await dispatch(
        WebAction.getCompanyType({
          limit: limit,
          search: search,
          page: page,
        }),
      );
    } finally {
      setLoading(false);
    }
  }, [dispatch, limit, search, page]);

  useEffect(() => {
    getData();
  }, [getData, search, limit, page]);

  return (
    <WebLayout pageName={"CompanyType"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <Breadcrumb
                mainTitle={"Setting"}
                subTitle={"Company Type List"}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <div className="header">
                  <h2>COMPANY TYPE LIST</h2>
                </div>
                <FilterSorting setLimit={setLimit} setSearch={setSearch} />
                <div className="body">
                  <div className="table-responsive">
                    <table
                      id="basicTable"
                      className="table table-hover table-checkable order-column contact_list"
                    >
                      <thead>
                        <tr>
                          <th className="center">#</th>
                          <th className="center">Company Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <TableLoader column={2} />
                        ) : getCompanyTypeList?.length > 0 ? (
                          getCompanyTypeList.map(
                            (dataList: any, index: number) => {
                              return (
                                <tr key={index}>
                                  <td>{from + index}</td>
                                  <td>{dataList.name}</td>
                                </tr>
                              );
                            },
                          )
                        ) : (
                          <tr>
                            <td colSpan={2} className="text-center">
                              No matching records found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {getCompanyTypeList?.length > 0 ? (
                    <Pagination
                      from={from}
                      to={to}
                      totalData={totalData}
                      setPage={setPage}
                      page={page}
                      pagination={pagination}
                      lastPage={lastPage}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default CompanyType;
