const WebType = {
  SET_ADD_ABOUT: "SET_ADD_ABOUT",
  SET_ABOUT_US: "SET_ABOUT_US",
  ADD_CERTIFICATION: "ADD_CERTIFICATION",
  SET_CERTIFICATION_LIST: "SET_CERTIFICATION_LIST",
  UPDATE_CERTIFICATION: "UPDATE_CERTIFICATION",
  DELETE_CERTIFICATION: "DELETE_CERTIFICATION",
  ADD_DOCUMENT: "ADD_DOCUMENT",
  SET_DOCUMENT_LIST: "SET_DOCUMENT_LIST",
  UPDATE_DOCUMENT: "UPDATE_DOCUMENT",
  DELETE_DOCUMENT: "DELETE_DOCUMENT",
  GET_STATE_LIST: "GET_STATE_LIST",
  GET_DISTRICT_LIST: "GET_DISTRICT_LIST",
  ADD_COMPANY_TYPE: "ADD_COMPANY_TYPE",
  SET_COMPANY_TYPE_LIST: "SET_COMPANY_TYPE_LIST",
  UPDATE_COMPANY_TYPE: "UPDATE_COMPANY_TYPE",
  DELETE_COMPANY_TYPE: "DELETE_COMPANY_TYPE",
  ADD_CATEGORY: "ADD_CATEGORY",
  SET_CATEGORY_LIST: "SET_CATEGORY_LIST",
  UPDATE_CATEGORY: "UPDATE_CATEGORY",
  DELETE_CATEGORY: "DELETE_CATEGORY",
  ADD_OFFER_PLAN: "ADD_OFFER_PLAN",
  GET_OFFER_PLAN: "GET_OFFER_PLAN",
};

export interface AddAbout {
  title: string;
  meta: string;
  content: string;
}

export interface AddCertificate {
  title: string;
  image: string;
}

export interface GetCertification {
  search: any;
  limit: number;
  page: number;
}

export interface UpdateCertificate {
  id: number;
  title: string;
  image: string;
}

export interface DeleteCertificate {
  id: number;
}

export interface AddDocument {
  title: string;
  image: string;
}

export interface GetDocument {
  search: any;
  limit: number;
  page: number;
}

export interface UpdateDocument {
  id: number;
  title: string;
  image: string;
}

export interface DeleteDocument {
  id: number;
}

export interface GetDistrict {
  state_id: number;
}

export interface AddCompanyType {
  name: string;
}

export interface GetCompanyType {
  search: any;
  limit: number;
  page: number;
}

export interface UpdateCompanyType {
  id: number;
  name: string;
}

export interface DeleteCompanyType {
  id: number;
}

export interface AddCategory {
  name: string;
  type: any;
}

export interface GetCategory {
  search: any;
  limit: number;
  page: number;
  type: any;
}

export interface UpdateCategory {
  id: number;
  name: string;
}

export interface DeleteCategory {
  id: number;
}

export interface AddOfferPlan {
  id: number;
  content: string;
}

export default WebType;
