import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import axios from "axios";
import { ADMIN_TOKEN, BASE_URL } from "../../utils/Constants";
import LogoutAction from "./LogoutAction";
import AttendanceType, {
  GetAttendanceList,
  GetEmployeeWiseAttendanceList,
} from "../type/attendanceType";

interface Action {
  type: string;
  payload?: any;
  token?: any;
}

const AttendanceAction = {
  getAttendanceList:
    (data: GetAttendanceList): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "attendance/get",
          {
            search: data.search,
            limit: data.limit,
            page: data.page,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "application/json",
            },
          },
        );

        dispatch({
          type: AttendanceType.GET_ATTENDANCE_LIST,
          payload: {
            data: result.data.data.data,
            currentPage: result.data.data.current_page,
            lastPage: result.data.data.last_page,
            totalData: result.data.data.total,
            from: result.data.data.from,
            to: result.data.data.to,
          },
        });
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  getEmployeeWiseAttendanceList:
    (
      data: GetEmployeeWiseAttendanceList,
    ): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "attendance/get-employee-wise",
          {
            id: data.id,
            search: data.search,
            limit: data.limit,
            page: data.page,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "application/json",
            },
          },
        );

        dispatch({
          type: AttendanceType.GET_EMPLOYEE_WISE_ATTENDANCE_LIST,
          payload: {
            data: result.data.data.attendance.data,
            currentPage: result.data.data.attendance.current_page,
            lastPage: result.data.data.attendance.last_page,
            totalData: result.data.data.attendance.total,
            from: result.data.data.attendance.from,
            to: result.data.data.attendance.to,
            employee: result.data.data.employee,
          },
        });
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },
};

export default AttendanceAction;
