import { ADMIN_TOKEN, ADMIN_USER_ID } from "../../utils/Constants";
import AuthType from "../type/authType";

export interface AuthState {
  authenticated: boolean;
  token: any;
}

export interface ReducerAction {
  type: string;
  payload?: any;
  token?: any;
}

const INITIAL_STATE: AuthState = {
  authenticated: false,
  token: null,
};

const AuthReducers = (state = INITIAL_STATE, action: ReducerAction) => {
  switch (action.type) {
    case AuthType.SET_USER:
      localStorage.setItem(ADMIN_TOKEN, JSON.stringify(action.token));
      localStorage.setItem(ADMIN_USER_ID, JSON.stringify(action.payload));
      return {
        ...state,
        authenticated: true,
        token: action.token,
      };

    case AuthType.RESET_AUTH:
      localStorage.removeItem(ADMIN_TOKEN);
      localStorage.removeItem(ADMIN_USER_ID);
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default AuthReducers;
