import React from "react";

const FilterSorting = ({ setLimit, setSearch }: any) => (
  <div
    className="g-3 px-4 mb-2 d-flex
              justify-content-between
              align-items-end"
  >
    <div className="col-md-3">
      <label className="mb-2">Show Entries</label>

      <select
        onChange={(e) => setLimit(e.target.value)}
        id="defaultSelect"
        className="form-select"
      >
        {["10", "20", "50", "100"].map((item: any, index: number) => {
          return (
            <option key={index} value={item}>
              {item}
            </option>
          );
        })}
      </select>
    </div>

    <div className="col-md-3">
      <div className="input-group input-group-merge mb-1">
        <input
          type="text"
          className="form-control"
          placeholder="Search..."
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
    </div>
  </div>
);
export default FilterSorting;
