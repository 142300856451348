import DashboardType from "../type/dashboardType";

export interface EnquiryAction {
  type: string;
  payload?: any;
}

const INITIAL_STATE = {
  contact: 0,
  emc: {},
  erm: {},
  stateSalary: 0,
};

const EnquiryReducers = (state = INITIAL_STATE, action: EnquiryAction) => {
  switch (action.type) {
    case DashboardType.SET_CONTACT_COUNT:
      return {
        ...state,
        contact: action.payload?.total_contact,
      };

    case DashboardType.SET_EMC_COUNT:
      return {
        ...state,
        emc: action.payload,
      };

    case DashboardType.SET_ERM_COUNT:
      return {
        ...state,
        erm: action.payload,
      };

    case DashboardType.SET_STATE_SALARY:
      return {
        ...state,
        stateSalary: action.payload?.count,
      };

    default:
      return state;
  }
};

export default EnquiryReducers;
