import { useState } from "react";
import { useDispatch } from "react-redux";
import EmployerAction from "../../stores/action/employerAction";

function ApproveEMCPaymentModal({
  modal,
  setModal,
  editableData,
  emcPaymentListData,
}: any) {
  const [loading, setLoading] = useState<any>();
  const [paymentStatus, setPaymentStatus] = useState<any>();
  const [rejectReason, setRejectReason] = useState<any>();
  const dispatch = useDispatch<any>();
  const closeModal = () => {
    setModal(false);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      await dispatch(
        EmployerAction.approveEMCPayment({
          id: editableData?.id,
          payment_status: paymentStatus,
          reject_reason: rejectReason,
        }),
      );
      closeModal();
      emcPaymentListData();
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  return (
    <div className="modal" style={{ display: modal ? "block" : "none" }}>
      <div className="modal-dialog modal-md" role="document">
        <form onSubmit={handleSubmit}>
          <div className="modal-content">
            <div className="modal-header p-0">
              <h5 className="modal-title m-0">Approve EMC Payment</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeModal}
              ></button>
            </div>
            <div className="modal-body p-0 py-3 mt-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>
                      Payment Status: <span className="text-danger">*</span>
                    </label>
                    <select
                      defaultValue={""}
                      className="form-control"
                      value={paymentStatus}
                      onChange={(e) => setPaymentStatus(e.target.value)}
                    >
                      <option value="" disabled selected>
                        Select Payment Status
                      </option>
                      <option value="APPROVED">Approved</option>
                      <option value="REJECTED">Rejected</option>
                    </select>
                  </div>
                  <div
                    className="form-group"
                    style={{
                      display: paymentStatus === "REJECTED" ? "block" : "none",
                    }}
                  >
                    <label>
                      Reason <span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      style={{ height: "100px" }}
                      value={rejectReason}
                      onChange={(e) => setRejectReason(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger light"
                data-bs-dismiss="modal"
                disabled={loading}
                onClick={closeModal}
              >
                Close
              </button>
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary px-4"
              >
                {loading ? <i className={"fa fa-spinner fa-spin"}></i> : null}
                &nbsp; Approve Payment
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ApproveEMCPaymentModal;
