import { Link } from "react-router-dom";
import WebLayout from "../../layout/WebLayout";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import ErmAction from "../../stores/action/ermAction";
import { ERM_URL } from "../../utils/Constants";
import toast from "react-hot-toast";
import ERMDetailsModal from "../../components/erm/ERMDetailsModal";
import Pagination from "../../components/Pagination";
import Breadcrumb from "../../components/Breadcrumb";
import FilterSorting from "../../components/FilterSorting";
import TableLoader from "../../components/TableLoader";

function ERMList() {
  const dispatch = useDispatch<any>();

  const [search, setSearch] = useState<any>("");
  const [limit, setLimit] = useState<any>(10);
  const [page, set_page] = useState<any>(1);
  const [editableData, setEditableData] = useState<string>();
  const [type, setType] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const {
    ermList,
    lastPage,
    totalData,
    from,
    to,
    totalPendingKYC,
    totalSendForApprovalKYC,
    totalApprovedKYC,
    totalRejectedKYC,
  } = useSelector((state: RootState) => state.erm);

  const maxPagesToShow = 5;
  const pagination = [];

  const startPage = Math.max(page - Math.floor(maxPagesToShow / 2), 1);
  const endPage = Math.min(startPage + maxPagesToShow - 1, lastPage);

  for (let i = startPage; i <= endPage; i++) {
    pagination.push(i);
  }

  const getERMList = useCallback(async () => {
    setLoading(true);
    try {
      await dispatch(
        ErmAction.getERMList({
          limit: limit,
          search: search,
          page: page,
          type: type,
        }),
      );
    } finally {
      setLoading(false);
    }
  }, [dispatch, limit, search, page, type]);

  const updateAccountStatus = async (id: number, status: string) => {
    try {
      await dispatch(
        ErmAction.updateERMPanelStatus({
          id,
          status,
        }),
      );
      await dispatch(
        ErmAction.getERMList({
          limit: limit,
          search: search,
          page: page,
          type: type,
        }),
      );
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    getERMList();
  }, [getERMList, search, limit, page, type]);

  return (
    <WebLayout pageName={"ERMList"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <Breadcrumb mainTitle={"ERM"} subTitle={"ERM List"} />
            </div>
          </div>
          <ERMDetailsModal
            editableData={editableData}
            getERMList={getERMList}
          />
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <div
                  className="header"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h2>ALL ERM LIST</h2>
                  <div>
                    <button
                      className="btn btn-warning btn-sm btn-block"
                      type="button"
                      onClick={() => setType("NOT VERIFIED")}
                    >
                      <i className="fa fa-users"></i> Pending
                      <span className="badge font-bold">{totalPendingKYC}</span>
                    </button>

                    <button
                      className="btn btn-info btn-sm btn-block m-l-10"
                      type="button"
                      onClick={() => setType("SEND FOR APPROVAL")}
                    >
                      <i className="fa fa-users"></i> Received For Approval
                      <span className="badge font-bold text-info bg-white">
                        {totalSendForApprovalKYC}
                      </span>
                    </button>

                    <button
                      className="btn btn-success btn-sm btn-block m-l-10"
                      type="button"
                      onClick={() => setType("VERIFIED")}
                    >
                      <i className="fa fa-users"></i> Approved
                      <span className="badge font-bold">
                        {totalApprovedKYC}
                      </span>
                    </button>

                    <button
                      className="btn btn-danger btn-sm btn-block m-l-10"
                      type="button"
                      onClick={() => setType("REJECTED")}
                    >
                      <i className="fa fa-users"></i> Rejected
                      <span className="badge font-bold">
                        {totalRejectedKYC}
                      </span>
                    </button>

                    <button
                      className="btn btn-primary btn-sm btn-block m-l-10"
                      type="button"
                      onClick={() => setType("")}
                    >
                      <i className="fa fa-users"></i> ALL
                      <span className="badge font-bold">{ermList?.length}</span>
                    </button>
                  </div>
                </div>
                <FilterSorting setLimit={setLimit} setSearch={setSearch} />
                <div className="body">
                  <div className="table-responsive">
                    <table
                      id="basicTable"
                      className="table table-hover table-checkable order-column contact_list"
                    >
                      <thead>
                        <tr>
                          <th className="center">#</th>
                          <th className="center">ERM ID</th>
                          <th className="center">Email</th>
                          <th className="center">Contact Details</th>
                          <th className="center">KYC Status</th>
                          <th className="center">Account Status</th>
                          <th className="center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <TableLoader column={7} />
                        ) : ermList?.length > 0 ? (
                          ermList.map((dataList: any, index: number) => {
                            return (
                              <tr key={index}>
                                <td>{from + index}</td>
                                <td>{dataList.ermId}</td>
                                <td>
                                  <Link
                                    to={`${ERM_URL}panel-login/${window.btoa(dataList.id)}`}
                                    className={"btn btn-primary btn-sm"}
                                    target={"_blank"}
                                  >
                                    {dataList.email}&nbsp;
                                    <i className={"fa fa-link"}></i>
                                  </Link>
                                </td>
                                <td className={"text-left"}>
                                  <strong>Name</strong> :{" "}
                                  {dataList.name || "N/A"}
                                  <br />
                                  <strong>Mobile</strong> :{" "}
                                  {dataList.phone || "N/A"}
                                  <br />
                                  <strong>Address</strong> :{" "}
                                  {dataList.address || "N/A"}
                                  <br />
                                  <strong>State</strong> :{" "}
                                  {dataList.state || "N/A"}
                                  <br />
                                  <strong>District</strong> :{" "}
                                  {dataList.district || "N/A"}
                                  <br />
                                  <strong>Pincode</strong> :{" "}
                                  {dataList.pincode || "N/A"}
                                </td>
                                <td>
                                  <button
                                    className={`btn btn-${dataList.kyc_status === "APPROVED" ? "success" : dataList.kyc_status === "SEND FOR APPROVAL" ? "info" : dataList.kyc_status === "PENDING" ? "warning" : "danger"} btn-sm cursor-not-allowed`}
                                  >
                                    {dataList.kyc_status === "SEND FOR APPROVAL"
                                      ? "RECEIVED FOR APPROVAL"
                                      : dataList.kyc_status}
                                  </button>
                                </td>
                                <td>
                                  {dataList?.panel_status === "ACTIVE" ? (
                                    <button
                                      className="btn btn-success btn-sm"
                                      onClick={() =>
                                        updateAccountStatus(
                                          dataList.id,
                                          "INACTIVE",
                                        )
                                      }
                                    >
                                      ACTIVE
                                    </button>
                                  ) : dataList?.panel_status === "INACTIVE" ? (
                                    <button
                                      className="btn btn-danger btn-sm"
                                      onClick={() =>
                                        updateAccountStatus(
                                          dataList.id,
                                          "ACTIVE",
                                        )
                                      }
                                    >
                                      INACTIVE
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td>
                                  <div className="d-flex">
                                    <button
                                      className="btn btn-dark btn-sm"
                                      type="button"
                                      data-bs-toggle="offcanvas"
                                      data-bs-target="#offcanvasRight"
                                      onClick={() => {
                                        setEditableData(dataList);
                                      }}
                                    >
                                      VIEW
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={7} className="text-center">
                              No matching records found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {ermList?.length > 0 ? (
                    <Pagination
                      from={from}
                      to={to}
                      totalData={totalData}
                      setPage={set_page}
                      page={page}
                      pagination={pagination}
                      lastPage={lastPage}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default ERMList;
