import { Link } from "react-router-dom";
import WebLayout from "../../layout/WebLayout";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { RootState } from "../../stores";
import { useDispatch, useSelector } from "react-redux";
import EmployerAction from "../../stores/action/employerAction";
import ApproveEMCPaymentModal from "../../components/employer/ApproveEMCPaymentModal";
import Pagination from "../../components/Pagination";
import Breadcrumb from "../../components/Breadcrumb";

interface EMCPayment {
  id: number;
  date: string;
  emp_no: string;
  cName: string;
  cEmail: string;
  total_amount: number;
  service_fee: number;
  gst_amount: number;
  amount_receivable: number;
  with_margin_total_amount: number;
  pay_slip: string;
  payment_status: string;
}

function EMCPaymentList() {
  const [search, setSearch] = useState<string>("");
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const dispatch = useDispatch<any>();
  const [modal, setModal] = useState<boolean>(false);
  const [editableData, setEditableData] = useState<EMCPayment | null>(null);

  const { emcPaymentList, lastPage, totalData, from, to } = useSelector(
    (state: RootState) => state.employer,
  );

  const maxPagesToShow = 5;

  const pagination = useMemo(() => {
    const startPage = Math.max(page - Math.floor(maxPagesToShow / 2), 1);
    const endPage = Math.min(startPage + maxPagesToShow - 1, lastPage);
    return Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i,
    );
  }, [lastPage, page]);

  const fetchEMCPaymentList = useCallback(async () => {
    await dispatch(
      EmployerAction.getEMCPaymentList({
        search,
        limit,
        page,
      }),
    );
  }, [dispatch, search, limit, page]);

  useEffect(() => {
    fetchEMCPaymentList();
  }, [fetchEMCPaymentList]);

  const openModal = (data: EMCPayment) => {
    setEditableData(data);
    setModal(true);
  };

  return (
    <WebLayout pageName="EMCPaymentList">
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <Breadcrumb
                mainTitle={"Manage Salary"}
                subTitle={"EMC Payment List"}
              />
            </div>
          </div>
          <ApproveEMCPaymentModal
            modal={modal}
            setModal={setModal}
            editableData={editableData}
            emcPaymentListData={fetchEMCPaymentList}
          />
          <div className="card">
            <div className="row g-3 px-4 mt-1 d-flex justify-content-between align-items-end">
              <div className="col-md-3">
                <label className="mb-2">Show Entries</label>
                <select
                  value={limit}
                  onChange={(e) => setLimit(Number(e.target.value))}
                  id="defaultSelect"
                  className="form-select"
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div className="col-md-6"></div>
              <div className="col-md-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                />
              </div>
            </div>
            <div className="body">
              <div className="table-responsive">
                <table className="table table-hover table-checkable">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Payment for This Month</th>
                      <th>EMC ID</th>
                      <th>Company Name</th>
                      <th>Email</th>
                      <th>Service Fee</th>
                      <th>GST</th>
                      <th>Amount Receivable</th>
                      <th>Pay Slip</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {emcPaymentList.length > 0 ? (
                      emcPaymentList.map(
                        (dataList: EMCPayment, index: number) => (
                          <tr key={index}>
                            <td className="center">{index + 1}</td>
                            <td className="center">{dataList?.date}</td>
                            <td className="center">
                              <Link
                                to={`/payment-employee-list/${dataList?.id}/${dataList?.emp_no}/${dataList?.cName}`}
                              >
                                {dataList?.emp_no}
                              </Link>
                            </td>
                            <td className="center">{dataList?.cName}</td>
                            <td className="center">{dataList?.cEmail}</td>
                            <td className="center">
                              Rs.
                              {dataList?.service_fee}
                            </td>
                            <td className="center">
                              Rs.
                              {dataList?.gst_amount}
                            </td>
                            <td className="center">
                              Rs.
                              {dataList?.with_margin_total_amount}
                            </td>
                            <td>
                              <Link to={dataList?.pay_slip} target="_blank">
                                <img
                                  src={dataList?.pay_slip}
                                  alt="Pay Slip"
                                  width={100}
                                />
                              </Link>
                            </td>
                            <td>
                              {dataList?.payment_status === "PENDING" ? (
                                <Link
                                  to="#"
                                  className="label label-warning rounded-0"
                                >
                                  {dataList?.payment_status}
                                </Link>
                              ) : dataList?.payment_status === "APPROVED" ? (
                                <Link
                                  to="#"
                                  className="label label-success rounded-0"
                                >
                                  {dataList?.payment_status}
                                </Link>
                              ) : dataList?.payment_status === "REJECTED" ? (
                                <Link
                                  to="#"
                                  className="label label-danger rounded-0"
                                >
                                  {dataList?.payment_status}
                                </Link>
                              ) : null}
                            </td>
                            <td>
                              <Link
                                to="#"
                                onClick={() => openModal(dataList)}
                                className="label label-primary rounded-0"
                              >
                                Approve
                              </Link>
                            </td>
                          </tr>
                        ),
                      )
                    ) : (
                      <tr>
                        <td colSpan={10} className="text-center">
                          No matching records found!
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {emcPaymentList?.length > 0 && (
                <Pagination
                  from={from}
                  to={to}
                  totalData={totalData}
                  setPage={setPage}
                  page={page}
                  pagination={pagination}
                  lastPage={lastPage}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default EMCPaymentList;
