import { Link } from "react-router-dom";

function RightSideBarModal({ editableData }: any) {
  return (
    <div
      className="offcanvas offcanvas-end"
      tabIndex={-1}
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
    >
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="offcanvasRightLabel">
          ASL Account Details
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        />
      </div>
      <div className="offcanvas-body">
        <ul className="nav nav-tabs tab-nav-right" role="tablist">
          <li role="presentation">
            <Link to="#personal" data-bs-toggle="tab" className="active">
              Personal Details
            </Link>
          </li>
          <li role="presentation">
            <Link to="#bank" data-bs-toggle="tab">
              Bank Details
            </Link>
          </li>
          <li role="presentation">
            <Link to="#kyc" data-bs-toggle="tab">
              KYC Details
            </Link>
          </li>
        </ul>
        <div className="tab-content">
          <div role="tabpanel" className="tab-pane active" id="personal">
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <th>ASL Id:</th>
                  <td>{editableData?.emp_no}</td>
                </tr>
                <tr>
                  <th>Company Name:</th>
                  <td>{editableData?.companyName}</td>
                </tr>
                <tr>
                  <th>Designation:</th>
                  <td>{editableData?.designation}</td>
                </tr>
                <tr>
                  <th>Department:</th>
                  <td>{editableData?.department}</td>
                </tr>
                <tr>
                  <th>Salary:</th>
                  <td>{editableData?.joining_salary}</td>
                </tr>
                <tr>
                  <th>Name:</th>
                  <td>{editableData?.name}</td>
                </tr>
                <tr>
                  <th>Father Name:</th>
                  <td>{editableData?.f_name}</td>
                </tr>
                <tr>
                  <th>Email:</th>
                  <td>{editableData?.email}</td>
                </tr>
                <tr>
                  <th>Mobile:</th>
                  <td>{editableData?.mobile}</td>
                </tr>
                <tr>
                  <th>State:</th>
                  <td>{editableData?.sName}</td>
                </tr>
                <tr>
                  <th>District:</th>
                  <td>{editableData?.dName}</td>
                </tr>
                <tr>
                  <th>Pin Code:</th>
                  <td>{editableData?.pincode}</td>
                </tr>
                <tr>
                  <th>Address:</th>
                  <td>{editableData?.present_address}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div role="tabpanel" className="tab-pane " id="bank">
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <th>Account Holder Name:</th>
                  <td>{editableData?.account_holder_name}</td>
                </tr>
                <tr>
                  <th>Account Number:</th>
                  <td>{editableData?.account_number}</td>
                </tr>
                <tr>
                  <th>Bank Name:</th>
                  <td>{editableData?.bank_name}</td>
                </tr>
                <tr>
                  <th>IFSC Code:</th>
                  <td>{editableData?.ifsc_code}</td>
                </tr>
                <tr>
                  <th>Branch:</th>
                  <td>{editableData?.branch_name}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div role="tabpanel" className="tab-pane " id="kyc">
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <th colSpan={2}>
                    <img
                      src={editableData?.pan_img}
                      className="mb-2"
                      alt={"PAN"}
                    />
                    <span>
                      <b>PAN Number:</b> {editableData?.pan_no}
                    </span>
                  </th>
                </tr>
                <tr>
                  <th colSpan={2}>
                    <img
                      src={editableData?.aadhar_img}
                      className="mb-2"
                      alt={"Aadhaar"}
                    />
                    <span>
                      <b>Aadhaar Number:</b> {editableData?.aadhar_no}
                    </span>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* <table className="table table-bordered">
          <tbody>
            <tr>
              <th>ASL Id:</th>
              <td>{editableData?.emp_no}</td>
            </tr>
            <tr>
              <th>Designation:</th>
              <td>{editableData?.designation}</td>
            </tr>
            <tr>
              <th>Department:</th>
              <td>{editableData?.department}</td>
            </tr>
            <tr>
              <th>Company Name:</th>
              <td>{editableData?.companyName}</td>
            </tr>
            <tr>
              <th>Name:</th>
              <td>{editableData?.name}</td>
            </tr>
            <tr>
              <th>Father Name:</th>
              <td>{editableData?.f_name}</td>
            </tr>
            <tr>
              <th>Email:</th>
              <td>{editableData?.email}</td>
            </tr>
            <tr>
              <th>Mobile:</th>
              <td>{editableData?.mobile}</td>
            </tr>
            <tr>
              <th>State:</th>
              <td>{editableData?.sName}</td>
            </tr>
            <tr>
              <th>District:</th>
              <td>{editableData?.dName}</td>
            </tr>
            <tr>
              <th>Pin Code:</th>
              <td>{editableData?.pincode}</td>
            </tr>
            <tr>
              <th>Address:</th>
              <td>{editableData?.present_address}</td>
            </tr>
            <tr>
              <th>Account Holder Name:</th>
              <td>{editableData?.account_holder_name}</td>
            </tr>
            <tr>
              <th>Account Number:</th>
              <td>{editableData?.account_number}</td>
            </tr>
            <tr>
              <th>Bank Name:</th>
              <td>{editableData?.bank_name}</td>
            </tr>
            <tr>
              <th>IFSC Code:</th>
              <td>{editableData?.ifsc_code}</td>
            </tr>
            <tr>
              <th>Branch:</th>
              <td>{editableData?.branch_name}</td>
            </tr>
            <tr>
              <th colSpan={2}>
                <img src={editableData?.pan_img} className="mb-2"></img>
                <span>
                  <b>PAN Number:</b> {editableData?.pan_no}
                </span>
              </th>
            </tr>
            <tr>
              <th colSpan={2}>
                <img src={editableData?.aadhar_img} className="mb-2"></img>
                <span>
                  <b>Aadhar Number:</b> {editableData?.aadhar_no}
                </span>
              </th>
            </tr>
          </tbody>
        </table> */}
      </div>
    </div>
  );
}

export default RightSideBarModal;
