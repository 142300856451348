import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import WebAction from "../stores/action/webAction";

type FormData = {
  title: string;
  image: string;
};

function AddCertification({
  setCatModal,
  catModal,
  type,
  editableData,
  getData,
}: any) {
  const handleClose = () => setCatModal(false);
  const dispatch = useDispatch<any>();
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, reset, setValue } = useForm<FormData>();

  useEffect(() => {
    if (type === "update" && editableData) {
      setValue("title", editableData.title || "");
      setValue("image", editableData.image || "");
    } else {
      setValue("title", "");
      setValue("image", "");
    }
  }, [type, editableData, catModal, setValue]);

  const formHandleSubmit = async (data: any) => {
    setLoading(true);
    try {
      const action =
        type === "add" ? WebAction.addCertificate : WebAction.updateCertificate;
      const payload: any =
        type === "add"
          ? { title: data.title, image: data.image[0] }
          : { id: editableData.id, title: data.title, image: data.image[0] };

      await dispatch(action(payload));
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      getData();
      reset();
      handleClose();
      setLoading(false);
    }
  };

  return (
    <div className="modal" style={{ display: catModal ? "block" : "none" }}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <form
            onSubmit={handleSubmit(formHandleSubmit)}
            className="needs-validation"
            encType="multipart/form-data"
          >
            <div className="modal-header p-0">
              <h5 className="modal-title m-0">
                {type === "add" ? "Add" : "Update"} Certification
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={handleClose}
              />
            </div>
            <div className="modal-body p-0 py-3 mt-3">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  {...register("title")}
                  placeholder="Name *"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="file"
                  className="form-control"
                  {...register("image")}
                  accept={"application/pdf"}
                  required={
                    editableData.image === "" ||
                    editableData.image === null ||
                    editableData.image === undefined
                  }
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                disabled={loading}
                onClick={handleClose}
              >
                Close
              </button>
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary"
              >
                {loading && (
                  <i
                    className={"fa fa-spinner fa-spin"}
                    style={{ color: "white" }}
                  />
                )}
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddCertification;
