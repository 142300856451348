import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import axios from "axios";
import { ADMIN_TOKEN, BASE_URL } from "../../utils/Constants";
import LogoutAction from "./LogoutAction";
import EnquiryType, {
  GetContact,
  GetEnquiry,
  UpdateContact,
} from "../type/enquiryType";
import toast from "react-hot-toast";

interface Action {
  type: string;
  payload?: any;
  token?: any;
}

const EnquiryAction = {
  getEnquiry:
    (data: GetEnquiry): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "enquiry-list",
          {
            search: data.search,
            limit: data.limit,
            page: data.page,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "application/json",
            },
          },
        );

        dispatch({
          type: EnquiryType.SET_ENQUIRY_LIST,
          payload: {
            data: result.data.data.enquiry.data,
            currentPage: result.data.data.enquiry.current_page,
            lastPage: result.data.data.enquiry.last_page,
            totalData: result.data.data.enquiry.total,
            from: result.data.data.enquiry.from,
            to: result.data.data.enquiry.to,
            totalEnquiry: result.data.data.totalEnquiry,
            todayEnquiry: result.data.data.todayEnquiry,
          },
        });
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },

  getContact:
    (data: GetContact): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "contact-list",
          {
            search: data.search,
            limit: data.limit,
            page: data.page,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "application/json",
            },
          },
        );

        dispatch({
          type: EnquiryType.SET_CONTACT_LIST,
          payload: {
            data: result.data.data.data,
            currentPage: result.data.data.current_page,
            lastPage: result.data.data.last_page,
            totalData: result.data.data.total,
            from: result.data.data.from,
            to: result.data.data.to,
          },
        });
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
      }
    },
  updateContactFeedback:
    (data: UpdateContact): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(ADMIN_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "update-feedback",
          {
            id: data.id,
            feedback: data.feedback,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "application/json",
            },
          },
        );

        dispatch({
          type: EnquiryType.UPDATE_CONTACT_FEEDBACK,
        });
        toast.success(result.data.msg);
      } catch (error: any) {
        toast.error(error);
      }
    },
};

export default EnquiryAction;
