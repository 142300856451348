import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import WebAction from "../stores/action/webAction";
import toast from "react-hot-toast";

type FormData = {
  name: string;
};

function AddCategoryModal({
  setCatModal,
  catModal,
  type,
  editableData,
  getData,
  offerType,
}: any) {
  const handleClose = () => setCatModal(false);
  const dispatch = useDispatch<any>();
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, reset, setValue } = useForm<FormData>();

  useEffect(() => {
    if (type === "update" && editableData) {
      setValue("name", editableData.name || "");
    } else {
      setValue("name", "");
    }
  }, [type, editableData, catModal, setValue]);

  const formHandleSubmit = async (data: any) => {
    setLoading(true);

    try {
      if (type === "add") {
        await dispatch(
          WebAction.addCategory({
            name: data.name,
            type: offerType,
          }),
        );
      } else {
        await dispatch(
          WebAction.updateCategory({
            id: editableData.id,
            name: data.name,
          }),
        );
      }
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setLoading(false);
      reset();
      handleClose();
      getData();
    }
  };
  return (
    <div className="modal" style={{ display: catModal ? "block" : "none" }}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <form
            onSubmit={handleSubmit(formHandleSubmit)}
            className="needs-validation"
            encType="multipart/form-data"
          >
            <div className="modal-header p-0">
              <h5 className="modal-title m-0">
                {type === "add" ? "Add" : "Update"}{" "}
                {offerType === "PLAN"
                  ? "Offer Plan Category"
                  : offerType === "POLICY"
                    ? "Policy Category"
                    : offerType === "FAQ"
                      ? "FAQ Question"
                      : ""}
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={handleClose}
              />
            </div>
            <div className="modal-body p-0 py-3 mt-3">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  {...register("name")}
                  placeholder={
                    offerType === "PLAN"
                      ? "Enter Category Name"
                      : offerType === "POLICY"
                        ? "Enter Category Name"
                        : offerType === "FAQ"
                          ? "Enter FAQ Question"
                          : ""
                  }
                  required
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger light"
                data-bs-dismiss="modal"
                disabled={loading}
                onClick={handleClose}
              >
                Close
              </button>
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary px-4"
              >
                {loading ? <i className={"fa fa-spinner fa-spin"}></i> : null}
                &nbsp; Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddCategoryModal;
