const TableLoader = ({ column }: any) => {
  return (
    <tr>
      <td colSpan={column} className="text-center">
        <div className="spinner-border text-dark" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </td>
    </tr>
  );
};
export default TableLoader;
