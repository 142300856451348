import { useCallback, useEffect, useState } from "react";
import WebLayout from "../../layout/WebLayout";
import { useParams } from "react-router-dom";
import MyEditor from "../../components/MyEditor";
import WebAction from "../../stores/action/webAction";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { RootState } from "../../stores";
import Breadcrumb from "../../components/Breadcrumb";

function OfferPlan() {
  const { id }: any = useParams<any>();
  const { offerPlanDetails } = useSelector((state: RootState) => state.web);

  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [editorData, setEditorData] = useState<string>("");
  const [title, setTitle] = useState<string>("");

  const dispatch = useDispatch<any>();
  const handleEditorChange = (newData: string) => {
    setEditorData(newData);
  };

  const formHandleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoader(true);
    try {
      await dispatch(
        WebAction.addOfferPlan({
          id: id,
          content: editorData,
        }),
      );
      setIsLoader(false);
    } catch (error: any) {
      setIsLoader(false);
      toast.error(error.message);
    }
  };

  const getOfferPlan = useCallback(async () => {
    await dispatch(WebAction.getOfferPlan(id));
  }, [dispatch, id]);

  useEffect(() => {
    getOfferPlan();
  }, [getOfferPlan]);

  useEffect(() => {
    if (offerPlanDetails?.name) setTitle(offerPlanDetails?.name);
    if (offerPlanDetails?.content) setEditorData(offerPlanDetails?.content);
    else setEditorData("");
  }, [offerPlanDetails?.content, offerPlanDetails?.name]);

  return (
    <WebLayout pageName={"OfferPlan"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <Breadcrumb
                mainTitle={"Setting"}
                subTitle={"Category List"}
                childTitle={"Offer Plan"}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={formHandleSubmit}>
                    <div className="row">
                      <div className="col-md-12">
                        <h4 className={"mb-3"}>{title}</h4>
                        <label htmlFor="Content">Enter Content</label>
                        <div className="form-group">
                          <MyEditor
                            initialValue={editorData}
                            onChange={handleEditorChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={isLoader}
                        >
                          {isLoader ? (
                            <i className={"fa fa-spinner fa-spin"}></i>
                          ) : (
                            ""
                          )}
                          &nbsp; Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default OfferPlan;
